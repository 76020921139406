import { Link, createFileRoute } from "@tanstack/react-router";
import { useSuspenseQuery } from "@tanstack/react-query";

import { LinkBox, Table } from "@pnpm-monorepo/core/src/ui/components";

import { ErrorComponent } from "../../../../components/ErrorComponent";
import { companyCampaignsQueryOptions } from "./-api/queryOptions";
import DefaultPendingComponent from "../../../../components/PendingComponent";
import { YearMonthString, YesNo } from "../../../../utils/renderers";
import AddNewModal from "./-components/AddNewModal";

export const Route = createFileRoute("/companies/$companyId/campaigns")({
  loader: ({ context: { queryClient }, params: { companyId } }) => {
    queryClient.ensureQueryData(companyCampaignsQueryOptions(companyId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
  component: CompanyCampaignsComponent,
});

function CompanyCampaignsComponent() {
  const params = Route.useParams();
  const companyCampaignsQuery = useSuspenseQuery(
    companyCampaignsQueryOptions(params.companyId)
  );
  const companyCampaigns = companyCampaignsQuery.data;

  return (
    <div className="flex w-full flex-col">
      <div className="flex justify-end">
        <AddNewModal />
      </div>
      <div className="flex flex-col w-full my-6 text-body-medium">
        {companyCampaigns.length ? (
          <Table.Wrapper>
            <Table.Root>
              <Table.Head>
                <Table.Row>
                  <Table.HeadCell>Månad</Table.HeadCell>
                  <Table.HeadCell>Avseende</Table.HeadCell>
                  <Table.HeadCell>Avtalsnr</Table.HeadCell>
                  <Table.HeadCell>I kampanj</Table.HeadCell>
                  <Table.HeadCell>Kampanjstatus</Table.HeadCell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {companyCampaigns?.map((campaign) => {
                  return (
                    <Table.Row key={campaign.id}>
                      <Table.RowCell>
                        <Link
                          to={`/companies/$companyId/campaigns/$campaignId`}
                          params={(prev) => ({
                            ...prev,
                            companyId: params.companyId,
                            campaignId: campaign.id,
                          })}
                          search={true}
                        >
                          <LinkBox>
                            <YearMonthString value={campaign.campaignMonth} />
                          </LinkBox>
                        </Link>
                      </Table.RowCell>
                      <Table.RowCell>{campaign.basisText}</Table.RowCell>
                      <Table.RowCell>{campaign.contractNumber}</Table.RowCell>
                      <Table.RowCell>
                        <YesNo value={campaign.campaignId} />
                      </Table.RowCell>

                      <Table.RowCell>{campaign.statusName}</Table.RowCell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table.Root>
          </Table.Wrapper>
        ) : (
          <div className="p-3 bg-tertiary-container text-center rounded-large">
            Det finns inga kampanjunderlag.
          </div>
        )}
        <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap"></div>
      </div>
    </div>
  );
}

import React from "react";
import { ErrorComponent, Link, createFileRoute } from "@tanstack/react-router";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale
dayjs.locale("sv"); // use locale

import DefaultPendingComponent from "../../../../../components/PendingComponent";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  LinkBox,
} from "@pnpm-monorepo/core/src/ui/components";
import {
  SmallDateString,
  YearMonthString,
} from "../../../../../utils/renderers";
import ExternalGRUS from "./-components/ExternalGrus";
import { CampaignBasisType } from "../../../../../utils/types";

import TextFieldWrapper from "../../../../../components/FormWrappers/TextFieldWrapper";
import {
  campaignBasisStatusQueryOptions,
  studioUsersQueryOptions,
} from "../../../../../utils/data/common";
import SelectWrapper from "../../../../../components/FormWrappers/SelectWrapper";
import { companyContractsQueryOptions } from "../../../../../utils/data/company/queryOptions";
import {
  campaignBasisQueryOptions,
  useUpdateCampaignbasisMutation,
} from "../../../../../utils/data/campaignbasis";
import CreateCampaignModal from "./-components/CreateCampaignModal";
import DeleteCampaign from "./-components/DeleteCampaign";
import { MdClose } from "@pnpm-monorepo/core/src/ui/icons";
import CreateGrusWorkorderModal from "./-components/CreateGrusWorkorderModal";

const contractSchema = z.object({
  id: z.number(),
  number: z.string(),
});

const schema = z.object({
  campaignMonth: z.string().min(1, "Månad är obligatoriskt"),
  campaignNote: z.string(),
  workOrderNote: z.string(),
  basisText: z.string(),
  status: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Status är obligatoriskt" }),
  assigned: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable(),
  contract: contractSchema
    .nullable()
    .refine((val) => val !== null, { message: "Kontrakt är obligatoriskt" }),
});

export const Route = createFileRoute(
  "/companies/$companyId/campaigns/$campaignId"
)({
  component: CampaignComponent,
  loader: ({ context: { queryClient }, params: { campaignId } }) => {
    queryClient.ensureQueryData(campaignBasisQueryOptions(campaignId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
});

function CampaignComponent() {
  const [edit, setEdit] = React.useState(false);
  const params = Route.useParams();
  const campaignQuery = useSuspenseQuery(
    campaignBasisQueryOptions(params.campaignId)
  );
  const campaign = campaignQuery.data;

  const studioUsersQuery = useQuery(studioUsersQueryOptions());
  const campaignBasisStatusQuery = useQuery(campaignBasisStatusQueryOptions());
  const companyContractsQuery = useQuery(
    companyContractsQueryOptions(params.companyId)
  );

  const mutation = useUpdateCampaignbasisMutation(params.campaignId);

  const { control, handleSubmit, reset } = useForm<CampaignBasisType>({
    resolver: zodResolver(schema),
    defaultValues: {
      campaignMonth: campaign.campaignMonth
        ? dayjs(campaign.campaignMonth).format("YYYY-MM")
        : dayjs().format("YYYY-MM"),
      campaignNote: campaign.campaignNote ?? "",
      workOrderNote: campaign.workOrderNote ?? "",
      assigned: campaign.assigned,
      status: campaign.status,
      basisText: campaign.basisText ?? "",
      contract: campaign.contract
        ? { id: campaign.contract.id, number: campaign.contract.number }
        : undefined,
    },
  });

  const onSubmit: SubmitHandler<CampaignBasisType> = (data) => {
    return mutation.mutate(
      {
        ...data,
        campaignMonth: dayjs(data.campaignMonth).isValid()
          ? dayjs(data.campaignMonth).format("YYYY-MM-DD")
          : undefined,
        id: Number(params.campaignId),
      },
      {
        onSuccess: () => {
          setEdit(false);
          reset(data);
        },
      }
    );
  };

  return (
    <div className="flex-1 flex flex-col ">
      <div className="flex mb-6 justify-start gap-2">
        <Link
          to={"/companies/$companyId/campaigns"}
          params={{ companyId: params.companyId }}
          search={true}
          className="flex-1 flex items-center"
        >
          <Button variant="tonal" iconLeft={<MdClose />}>
            Stäng
          </Button>
        </Link>
        <DeleteCampaign /> <CreateCampaignModal />{" "}
        <CreateGrusWorkorderModal campaign={campaign} />
      </div>
      <div className="flex flex-col lg:flex-row gap-4">
        {!edit && (
          <Card>
            <CardHeader
              title={
                <>
                  Kampanjunderlag{" "}
                  {campaign?.campaignMonth ? (
                    <YearMonthString value={campaign?.campaignMonth} />
                  ) : null}{" "}
                  {campaign?.companyName ? campaign?.companyName : ""}
                </>
              }
              actions={
                <>
                  <Button
                    className="md:flex hidden"
                    onClick={() => setEdit(true)}
                    iconLeft={<Icon icon="edit" size={20} />}
                  >
                    Redigera
                  </Button>
                  <IconButton
                    variant="tonal"
                    className="md:hidden flex"
                    onClick={() => setEdit(true)}
                    icon={<Icon icon="edit" size={20} />}
                  />
                </>
              }
            />
            <CardContent>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Företag
                </div>
                <div className="text-body-medium">{campaign.companyName}</div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Månad
                </div>
                <div className="text-body-medium">
                  <YearMonthString value={campaign.campaignMonth} />
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Avseende
                </div>
                <div className="text-body-medium">{campaign.basisText}</div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Avtalsnr
                </div>
                <div className="text-body-medium">
                  <LinkBox>
                    <Link
                      to={`/companies/$companyId/contracts/$contractId`}
                      params={{
                        companyId: campaign.contract.company.id,
                        contractId: campaign.contract.id,
                      }}
                    >
                      {campaign.contract.number}
                    </Link>
                  </LinkBox>
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Anteckning
                </div>
                <div className="text-body-medium">{campaign.campaignNote}</div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Arbetsorderinfo
                </div>
                <div className="text-body-medium">{campaign.workOrderNote}</div>
              </div>
              {campaign.campaignId && (
                <div className="mb-4">
                  <div className="text-label-medium text-on-surface-variant">
                    I kampanj
                  </div>
                  <div className="text-body-medium">
                    JA,{" "}
                    <LinkBox>
                      <Link
                        to={`/campaigns/$campaignId`}
                        params={{
                          campaignId: campaign.campaignId,
                        }}
                      >
                        {campaign.campaignDate}
                      </Link>
                    </LinkBox>
                  </div>
                </div>
              )}
              {!campaign.campaignId && (
                <div className="mb-4">
                  <div className="text-label-medium text-on-surface-variant">
                    I kampanj
                  </div>
                  <div className="text-body-medium">Nej</div>
                </div>
              )}
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Formgivare
                </div>
                <div className="text-body-medium">
                  {campaign.assigned ? (
                    <span>{campaign.assigned.name}</span>
                  ) : (
                    <span className="">&lt;Ej angivet&gt;</span>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Skapad
                </div>
                <div className="text-body-medium">
                  <SmallDateString value={campaign.createdAt} />{" "}
                </div>
              </div>
              <div className="mb-4">
                <div className="text-label-medium text-on-surface-variant">
                  Status
                </div>
                <div className="text-body-medium">{campaign.status.name}</div>
              </div>
            </CardContent>
          </Card>
        )}

        {edit && (
          <Card variant="outlined">
            <CardHeader
              title={
                <>
                  Kampanjunderlag{" "}
                  {campaign?.campaignMonth ? (
                    <YearMonthString value={campaign?.campaignMonth} />
                  ) : null}{" "}
                  {campaign?.companyName ? campaign?.companyName : ""}
                </>
              }
              actions={
                <>
                  <Button
                    className="md:flex hidden"
                    onClick={() => setEdit(false)}
                    iconLeft={<Icon icon="close" size={20} />}
                  >
                    Avsluta redigering
                  </Button>
                  <IconButton
                    variant="tonal"
                    className="md:hidden flex"
                    onClick={() => setEdit(false)}
                    icon={<Icon icon="close" />}
                  />
                </>
              }
            />
            <CardContent className=" border-t-[1px] border-outline-variant">
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Månad
                </div>
                <div className="pt-2">
                  <TextFieldWrapper
                    name="campaignMonth"
                    control={control}
                    variant="month"
                  />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Avseende
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="basisText" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Avtalsnr
                </div>
                <div className="pt-2">
                  {!companyContractsQuery.isPending ? (
                    <SelectWrapper
                      name="contract"
                      control={control}
                      className="min-w-32"
                      options={companyContractsQuery.data}
                      getOptionLabel={(option) => option?.number}
                    />
                  ) : null}
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Anteckningar
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="campaignNote" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Arbetsorderinfo
                </div>
                <div className="pt-2">
                  <TextFieldWrapper name="workOrderNote" control={control} />
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Formgivare
                </div>
                <div className="pt-2">
                  {!studioUsersQuery.isPending ? (
                    <SelectWrapper
                      name="assigned"
                      control={control}
                      className="min-w-32"
                      options={studioUsersQuery.data}
                      getOptionLabel={(option) => option?.name}
                    />
                  ) : null}
                </div>
              </div>
              <div className="mb-6">
                <div className="text-label-medium text-on-surface-variant">
                  Status
                </div>
                <div className="pt-2">
                  {!campaignBasisStatusQuery.isPending ? (
                    <SelectWrapper
                      name="status"
                      control={control}
                      className="min-w-32"
                      options={campaignBasisStatusQuery.data}
                      getOptionLabel={(option) => option?.name}
                    />
                  ) : null}
                </div>
              </div>
            </CardContent>
            <div className="flex justify-end gap-2 p-3">
              <Button
                variant="filled"
                onClick={handleSubmit(onSubmit)}
                disabled={mutation.isPending}
              >
                Spara & stäng
              </Button>
            </div>
          </Card>
        )}
        <ExternalGRUS id={params.campaignId} />
      </div>
    </div>
  );
}

import { Button, Icon, Search } from "@pnpm-monorepo/core/src/ui/components";

import RouterProgress from "../../../components/RouterProgress";
import AddNewCompany from "./AddNewCompany";

interface SearchBarProps {
  searchDraft: string;
  onSearchChange: (value: string) => void;
  onToggleFilter: () => void;
  isFilterOpen: boolean;
  onClearFilters: () => void;
  hasFilters: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchDraft,
  onSearchChange,
  onToggleFilter,
  isFilterOpen,
  onClearFilters,
  hasFilters,
}) => (
  <div className="z-10 flex min-w-[300px] flex-col text-on-surface sticky top-0 bg-surface">
    <div className="block z-50 pb-3 pt-4 px-4">
      <Search
        id="companies-search"
        name="search"
        placeholder="Sök företag"
        clearOnEscape={true}
        onChange={onSearchChange}
        defaultValue={searchDraft}
      />
      <div className="mt-4 mb-2 flex gap-2 items-center">
        <div className="flex grow gap-2 items-center">
          <Button
            variant={isFilterOpen ? "filled" : "outlined"}
            iconLeft={
              isFilterOpen ? (
                <Icon icon="close" size={20} />
              ) : (
                <Icon icon="tune" size={20} />
              )
            }
            onClick={onToggleFilter}
          >
            Filter
          </Button>
          {hasFilters && <Button onClick={onClearFilters}>Rensa allt</Button>}
          <RouterProgress />
        </div>
        <AddNewCompany />
      </div>
    </div>
  </div>
);

export default SearchBar;

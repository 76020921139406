import { useState } from "react";

import {
  Button,
  Dialog,
  Icon,
} from "@pnpm-monorepo/core/src/ui/components";

import { MdDelete } from "@pnpm-monorepo/core/src/ui/icons";

import { useDeleteRequestMutation } from "../../../../../utils/data/request";

const DeleteRequest = ({ id }: { id: number | string }) => {
  const [open, setOpen] = useState<boolean>(false);
  const removeRequest = useDeleteRequestMutation(id);

  const handleDelete = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const handleOk = (): void => {
    removeRequest.mutate(
      {
        id,
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  return (
    <div>
      <Button onClick={handleDelete} icon={<Icon icon="delete" size={22} />} size="small" variant="outlined">
        Ta bort
      </Button>
      <Dialog
        icon={<MdDelete />}
        open={open}
        onClose={handleClose}
        headline="Ta bort ärende?"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              variant="filled"
              className="bg-error text-on-error"
              onClick={handleOk}
              disabled={removeRequest.isPending}
            >
              Ta bort
            </Button>
          </>
        }
      ></Dialog>
    </div>
  );
};

export default DeleteRequest;

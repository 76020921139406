import { HistoryState } from "@tanstack/react-router";

export type CustomHistoryState = {
  from?: string;
} & HistoryState;

export type SortOrder = "asc" | "desc";

export type OfferActionTextType = {
  id: string;
  name: string;
};

export type OfferActionType = {
  id: string;
  name: string;
};

export type OfferTypeType = {
  id: string;
  name: string;
};

export type OfferServiceTextType = {
  id: string;
  name: string;
};

export type OfferServiceType = {
  id: string;
  name: string;
  active: boolean;
  canBeDeleted: boolean;
};

export type OfferWorthType = {
  id: string;
  name: string;
  active: boolean;
  canBeDeleted: boolean;
};

export type OfferPrepositionType = {
  id: string;
  name: string;
};

export type BarcodeType = {
  id: string;
  name: string;
};

export const BarcodeTypes: string[] = ["EAN13", "CODE128"];

export type ProductCategoryType = {
  id: number;
  offerActionId?: number;
  name: string;
  active: boolean;
  description: string;
  colour: string;
  offerAction: OfferActionType;
  offerType: OfferTypeType;
  limitedCashing: boolean;
  limitedConsumerDays: boolean;
};

export type PlaceType = {
  id: number;
  name: string;
  active: boolean;
  canBeDeleted: boolean;
};

export type IndustryType = {
  id: number;
  name: string;
  active: boolean;
  canBeDeleted: boolean;
};

export type ProductType = {
  id: number;
  name: string;
  price: string;
  description?: string;
  productCategory: ProductCategoryType;
  active: boolean;
  canBeDeleted: boolean;
};

export type SellerType = {
  id: number;
  name: string;
  email?: string;
  phone?: string;
  sellerCompany?: string;
  active: boolean;
  canBeDeleted: boolean;
};

export type ChainType = {
  id: number;
  name: string;
  active: boolean;
  canBeDeleted: boolean;
};

export type CompanyType = {
  id: number;
  name: string;
  number: string;
  friendlyName?: string;
  organizationNumber?: string;
  seller: SellerType;
  chain: ChainType;
  emailStatistics?: string;
  contractSigner?: string;
  description?: string;
  status: StatusType;
  address: AddressType;
  image?: ImageType;
};

export type AddressType = {
  id: number;
  companyId?: number | string;
  webSiteDisplayText: string;
  webSite?: string;
  facebookDisplayText: string;
  facebook?: string;
  instagramDisplayText: string;
  instagram?: string;
  phone?: string;
  contact: string;
  cellphone: string;
  email: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  latitude: string;
  longitude: string;
  formattedAddress?: string;
  hideMap: boolean;
};

export type PeriodType = {
  id: number;
  open: {
    day: string;
    dayName: string;
    time: string;
  };
  close: {
    day: string;
    dayName: string;
    time: string;
  };
};

export type OpeningHourType = {
  id: number;
  storeId: number;
  openNow: boolean;
  periods: PeriodType[];
};

export type StoreType = {
  id: number;
  name: string;
  companyId: number;
  place: PlaceType;
  address: AddressType;
  active: boolean;
  description: string;
  openingHour: OpeningHourType;
  openTimeComment?: string;
  hideOpenTimes: boolean;
  emailStatistics: string;
  geoFenceUse: boolean;
  geoFenceDistanceFromMeters: number;
  images: ImageType[];
  openingHourText?: string;
};

export type AssignedType = {
  id: number;
  name: string;
};

export type StatusType = {
  id: number;
  name: string;
};

export type GenderType = {
  id: number;
  name: string;
};

export type WeekType = {
  id: number;
  name: string;
};

export type ImageType = {
  id: number;
  url: string;
  filename?: string;
  originalName?: string;
  imageCategoryId: number;
  companyId: number;
  storeId?: number;
};

export type ContractType = {
  id: number;
  number: string;
  rusGuid?: string;
  validFrom: Date | string;
  validTo?: Date | string | undefined;
  contractDate: Date | string;
  industry?: IndustryType;
  seller?: SellerType;
  companySigner?: string;
  digitallyCampaignOther?: string;
  status: StatusType;
};

export type ContractProductPlaceType = {
  id: number;
  price?: number | string;
  validFrom?: Date | string | undefined;
  validTo?: Date | string | undefined;
  rusGuid?: string;
  contract: ContractType;
  product: ProductType;
  place: PlaceType;
  status?: StatusType;
};

export type OfferType = {
  id: number;
  number: string;
  originalPrice?: string;
  restrictions?: string;
  information?: string;
  offerActionLink?: string;
  barcode?: string;
  barcodeType?: string;
  offerActionText?: OfferActionTextType;
  offerWorth?: OfferWorthType;
  offerWorthText?: string;
  offerService?: OfferServiceTextType;
  offerServiceText?: string;
  offerPreposition?: OfferPrepositionType;

  industry?: IndustryType;
  company: CompanyType;
  product?: ProductType;
  status?: StatusType;
  contractProductPlaces?: ContractProductPlaceType[];
  stores?: StoreType[];

  images?: ImageType[];

  priority: number;
  maxConsumerDays: number;
  validToExtendByMaxConsumerDays: boolean;
  proofMessage?: string;
  validFrom?: Date | string | undefined;
  validTo?: Date | string | undefined;
  pauseFrom?: Date | string | undefined;
  pauseTo?: Date | string | undefined;
  hideValidFromTo: boolean;
  campaignText?: string;

};

export type OfferEventType = {
  companyName: string;
  consumerEMail: string;
  consumerId: number;
  consumerName?: string;
  createdAt: Date;
  event: string;
  offerId: number;
  placeId: number;
  placeName: string;
  receiverId: number;
  storeId?: number;
  storeName?: string;
};

export type CampaignType = {
  id: number;
  repeatId: number;
  checkActivityMonthsBack: number;
  name: string;
  subject: string;
  header: string;
  description: string;
  template: {
    id: number;
    name: string;
    key: string;
  };
  campaignBasis: CampaignBasisType;
  status: StatusType;
  campaignDate: string;
  campaignDateUntil: string;
  updatedAt: string;
  createdAt: string;
  relatedCampaignDate: string;
  relatedCampaignId: number;
};

export type CampaignBasisType = {
  id: number;
  basisText: string;
  workOrderNote?: string;
  campaignNote?: string;
  campaignMonth: string;
  status: StatusType;
  assigned: AssignedType;
  contract: ContractType;
};

export type ConsumerType = {
  id: number;
  userId: string;
  firstName?: string;
  lastName?: string;
  fullName: string;
  email: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  formattedAddress: string;
  gender?: GenderType;
  dateOfBirth?: Date;
  mobileNumber?: string;
  acceptPushNoticeDate: string;
  acceptPushNotice: boolean;
  acceptTermsOfConditionDate: string;
  acceptTermsOfCondition: boolean;
  acceptPrivacyPolicyDate: string;
  acceptPrivacyPolicy: boolean;
  acceptNewsLetterDate: string;
  acceptNewsLetter: boolean;
  acceptUserBehaviorStorageDate: string;
  acceptUserBehaviorStorage: boolean;
  acceptPersonalizingDate: string;
  acceptPersonalizing: boolean;
  code: string;
  source: string;
  rusId?: string;
  welcomePlace: PlaceType;
  place: PlaceType;
  status: StatusType;
  updatedAt: string;
  createdAt: string;
  emailVerifiedSentDate?: Date;
  emailVerified: boolean;
  receiverId: number;
  profileBirthDateDoCheck: number;
  profileGenderDoCheck: number;
  profileAcceptGeoFenceDoCheck: number;
  acceptGeoFenceDate: string;
  acceptGeoFence: boolean;
  profileAcceptGeoLocationStorageDoCheck: number;
  acceptGeoLocationStorageDate: string;
  acceptGeoLocationStorage: boolean;
  acceptTrackingAcrossSitesDoCheck: number;
  acceptTrackingAcrossSitesDate?: Date;
  acceptTrackingAcrossSites: boolean;
  acceptReviewQuestionDoCheck: number;
  acceptReviewQuestionDate?: Date;
  acceptReviewQuestion: boolean;
};

export type ReceiverType = {
  id: number;
  code: string;
  consumerId: number;
  placeId: number;
  placeName: string;
  firstName?: string;
  lastName?: string;
  fullName: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  week_ISO: string;
  createdAt: string;
  receiverId: number;
  isActiveConsumer: boolean;
};

export type RecevierAffiliationType = {
  id: number;
  company: CompanyType;
  welcomeOfferActiveDate: Date;
  createdAt: Date;
};

/// Systemsetting

export type SystemSettingType = {
  id: number;
  key: string;
  value: string;
  description: string;
};

/// Scheduler

type Content = {
  id: number;
  name: string;
  description: string;
  push_IsActive: boolean;
  push_Title: string;
  push_Body: string;
  push_CustomData: string;
};

type Executer = {
  id: number;
  name: string;
  receiverSql: string;
  content: Content;
};

export type SchedulerType = {
  id: number;
  repeatMinutes: number;
  lastRepeat: Date;
  parameters: string;
  active: boolean;
  executer: Executer;
};

/// Logs

export type DataEntry = {
  field: string;
  current: string;
  previous: string;
  isImage: boolean;
};

export type LogEntry = {
  action: string;
  when: string;
  who: string;
  section: string;
  data: DataEntry[];
};

export type EmailTestLogEntry = {
  when: Date;
  email: string;
};

// Support

export type RequestSatus = StatusType & {
  color: string;
};

export type RequestDetails = {
  email?: string;
  name?: string;
  welcomeCode?: string;
  date?: string;
  message?: string;
};

export type RequestItem = {
  id: string;
  ticketNumber: string;
  details?: RequestDetails;
  status: RequestSatus;
  createdAt: string;
  updatedAt: string;
};

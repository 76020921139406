import {
  Button,
  Dialog,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";
import { useEffect, useMemo, useState } from "react";
import { Route } from "..";
import { useUpdateConsumerUserIdMutation } from "../-api/queryOptions";

import { ConsumerType } from "../../../../utils/types";
import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  ChangeConsumerIdCommandType,
  fetchAzureADB2CUser,
} from "../-api/consumers";
import { useSnackbar } from "notistack";

const schema = z.object({
  id: z.number(),
  userId: z.string().min(1, { message: "UserId är obligatoriskt" }),
});

const ChangeUserId = ({ consumer }: { consumer: ConsumerType }) => {
  const params = Route.useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [foundUserId, setFoundUserId] = useState<string | null>(null);
  const [canSearch, setCanSearch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (consumer.userId.includes("auth0")) {
      setCanSearch(true);
    } else {
      setCanSearch(false);
    }
  }, [consumer.userId]);

  const values = useMemo(() => {
    return { id: consumer.id, userId: consumer.userId };
  }, [consumer]);

  const mutation = useUpdateConsumerUserIdMutation(params.consumerId);

  const { control, handleSubmit, setValue, watch, register, reset } = useForm({
    resolver: zodResolver(schema),
    values,
  });

  const watchUserId = watch("userId");

  const handleAdd = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    setFoundUserId(null)
    setCanSearch(false);
    reset();
    mutation.reset();
  };

  const handleAzureADB2CSearch = async (): Promise<void> => {
    setLoading(true);
    const response = await fetchAzureADB2CUser(consumer.email);
    if (response?.id) {
      setFoundUserId(response.id);
      setValue("userId", response.id);
    }
    setLoading(false);
  };

  const onSubmit: SubmitHandler<ChangeConsumerIdCommandType> = (data) => {
    return mutation.mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          enqueueSnackbar("UserId uppdaterad");
          reset();
        },
      }
    );
  };

  return (
    <div>
      <>
        <Button onClick={handleAdd}>Ändra</Button>
      </>

      <Dialog
        icon={<Icon icon={"id_card"} />}
        open={open}
        onClose={handleClose}
        headline="Ändra UserId"
        text="UserId är kopplat till Azure AD B2C. En användare med samma UserId och
        Login måste även finnas där."
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Stäng
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara
            </Button>
          </>
        }
      >
        <div className="my-2">
          {mutation.error && (
            <div className="flex flex-col text-body-medium break-words my-2 mb-6 p-3 bg-error-container text-on-error-container">
              {Object.entries(mutation.error.response.data.errors).map(
                ([key, obj]) => (
                  <div key={key}>{obj[0]}</div>
                )
              )}
            </div>
          )}

          {/* {mutation.isSuccess && (
            <div className="flex text-body-medium mb-6 my-2 p-3 bg-tertiary-container text-on-tertiary-container">
              UserId uppdaterad
            </div>
          )} */}
          {canSearch && (
            <div className="text-label-medium text-on-secondary-container mb-6 flex flex-col p-4 bg-secondary-container rounded-medium">
              {foundUserId ? (
                <div className="text-label-medium ">
                  Hittade ett user id <i>{foundUserId}</i>. Klicka Spara för att uppdatera. 
                </div>
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  fullwidth
                  onClick={handleAzureADB2CSearch}
                >
                  {loading
                    ? "Söker...."
                    : "Sök i Azure AD B2C efter ett UserId"}
                </Button>
              )}
            </div>
          )}

          <div className="text-label-medium text-on-surface-variant">
            UserId
          </div>
          <div className="pt-2">
            <TextFieldWrapper
              name="userId"
              control={control}
              rightElement={
                watchUserId ? (
                  <IconButton
                    onClick={() => {
                      setValue("userId", "");
                      mutation.reset();
                    }}
                    size="small"
                    disabled={mutation.isPending}
                    icon={<Icon icon={"clear"} />}
                  />
                ) : null
              }
            />
            <input type="hidden" {...register("id")} />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ChangeUserId;

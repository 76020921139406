import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/analytics/store")({
  component: () => (
    <div className="w-full h-full relative">
      <iframe
        allowFullScreen={true}
        className="w-full h-full border-none block"
        src="https://app.powerbi.com/reportEmbed?reportId=57acb805-78c3-48ee-8679-8c6235907084&autoAuth=true&ctid=4a34ac43-7589-41e9-b9d8-18a78e7675c1&hideToolbar=true&navContentPaneEnabled=false&pageName=ReportSection7b219594dc7e1833b795"
      ></iframe>
    </div>
  ),
});

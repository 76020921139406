import { useForm, SubmitHandler } from "react-hook-form";

import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale
dayjs.locale("sv"); // use locale

import { Button } from "@pnpm-monorepo/core/src/ui/components";
import NumberInputWrapper from "../../../../components/FormWrappers/NumberInputWrapper";
import SwitchWrapper from "../../../../components/FormWrappers/SwitchWrapper";
import TextareaWrapper from "../../../../components/FormWrappers/TextareaWrapper";
import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";

import { useUpdateOfferTermsMutation } from "../-api/queryOptions";
import { OfferType } from "../../../../utils/types";
import { useSnackbar } from "notistack";

export function TermsFields({ offer }: { offer: OfferType }) {
  const updateOfferTermsMutation = useUpdateOfferTermsMutation(offer.id);
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, reset } = useForm<OfferType>({
    defaultValues: {
      id: offer.id,
      priority: offer.priority,
      maxConsumerDays: offer.maxConsumerDays,
      validToExtendByMaxConsumerDays: offer.validToExtendByMaxConsumerDays,
      proofMessage: offer.proofMessage ?? "",
      hideValidFromTo: offer.hideValidFromTo,
      validFrom: offer.validFrom
        ? dayjs(offer.validFrom).format("YYYY-MM-DD")
        : undefined,
      validTo: offer.validTo
        ? dayjs(offer.validTo).format("YYYY-MM-DD")
        : undefined,
      pauseFrom: offer.pauseFrom
        ? dayjs(offer.pauseFrom).format("YYYY-MM-DD")
        : undefined,
      pauseTo: offer.pauseTo
        ? dayjs(offer.pauseTo).format("YYYY-MM-DD")
        : undefined,
    },
  });

  const onSubmit: SubmitHandler<OfferType> = (data) => {
    return updateOfferTermsMutation.mutate(
      {
        ...data,
      },
      {
        onSuccess: () => {
          enqueueSnackbar('"Villkor" uppdaterad');
          reset(data);
        },
        onError: (error) => {
          console.error(error);
          enqueueSnackbar(`Något gick fel: ${error.message}.`, {
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <>
      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Giltig från och med
        </div>

        <div className="pt-2">
          <TextFieldWrapper name="validFrom" control={control} variant="date" />
        </div>
      </div>
      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Giltig till och med
        </div>

        <div className="pt-2">
          <TextFieldWrapper name="validTo" control={control} variant="date" />
        </div>
      </div>

      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Antalet dagar erbjudandet gäller för konsumenten
        </div>

        <div className="pt-2">
          <NumberInputWrapper
            name="maxConsumerDays"
            control={control}
            min={0}
            max={1000}
          />
        </div>
      </div>

      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Giltigt till och med datum flyttas fram med det antal dagar ett
          erbjudande gäller (Status blir Avslutat – Utgående)
        </div>

        <div className="pt-2">
          <SwitchWrapper
            name="validToExtendByMaxConsumerDays"
            control={control}
          />
        </div>
      </div>

      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Dölj giltighetstid
        </div>
        <div className="pt-2">
        <SwitchWrapper
            name="hideValidFromTo"
            control={control}
          />
        </div>
      </div>

      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Sorteringsprioritet
        </div>

        <div className="pt-2">
          <NumberInputWrapper
            name="priority"
            control={control}
            min={0}
            max={10}
          />
        </div>
      </div>

      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Eventuell information till Support/Annons
        </div>

        <div className="pt-2">
          <TextareaWrapper name="proofMessage" control={control} />
        </div>
      </div>

      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Paus från och med
        </div>

        <div className="pt-2">
          <TextFieldWrapper name="pauseFrom" control={control} variant="date" />
        </div>
      </div>
      <div className="mb-6">
        <div className="text-label-medium text-on-surface-variant">
          Paus till och med
        </div>

        <div className="pt-2">
          <TextFieldWrapper name="pauseTo" control={control} variant="date" />
        </div>
      </div>

      <div id="offer-action_terms" className="flex mb-4 justify-end gap-2">
        <Button
          variant="filled"
          onClick={handleSubmit(onSubmit)}
          disabled={updateOfferTermsMutation.isPending}
        >
          Spara
        </Button>
      </div>
    </>
  );
}

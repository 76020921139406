import { cs } from "../../../utils";
import {
  TableProps,
  TableWrapperProps,
  TableBodyProps,
  TableHeadProps,
  TableRowProps,
  TableRowCellProps,
  TableHeadCellProps,
} from "./Table.types";

const Wrapper: React.FC<TableWrapperProps> = (props) => {
  const { className, children } = props;

  return (
    <div
      className={cs(
        "rounded-large mx-0 overflow-auto border-[1px] border-outline-variant",
        className
      )}
    >
      {children}
    </div>
  );
};

const Root: React.FC<TableProps> = (props) => {
  const { className, children } = props;

  return (
    <table
      className={cs(
        "w-full table-auto text-body-small text-on-surface",
        className
      )}
    >
      {children}
    </table>
  );
};

const Head: React.FC<TableHeadProps> = (props) => {
  const { className, children } = props;

  return (
    <thead
      className={cs(
        "bg-surface-container-highest text-body-medium overflow-hidden",
        className
      )}
    >
      {children}
    </thead>
  );
};

const Body: React.FC<TableBodyProps> = (props) => {
  const { className, children } = props;

  return (
    <tbody className={cs("text-body-medium ", className)}>{children}</tbody>
  );
};

const Row: React.FC<TableRowProps> = (props) => {
  const { className, children, ...other } = props;

  return (
    <tr
      className={cs(
        "overflow-hidden border-outline-variant border-b-[1px] last:border-b-0",
        className
      )}
      {...other}
    >
      {children}
    </tr>
  );
};

const RowCell: React.FC<TableRowCellProps> = (props) => {
  const {
    className,
    children,
    justify = "justify-start",
    style,
    ...other
  } = props;

  return (
    <td
      className={cs(
        "overflow-hidden px-4 py-3 border-r-[1px] last:border-r-0 border-outline-variant",
        className
      )}
      style={style}
      {...other}
    >
      <span className={cs("flex ", justify)}>{children}</span>
    </td>
  );
};

const HeadCell: React.FC<TableHeadCellProps> = (props) => {
  const { className, children, justify = "justify-start", ...other } = props;

  return (
    <th
      className={cs(
        "min-w-max overflow-hidden px-4 py-3 text-left font-medium border-r-[1px] border-outline-variant last:border-r-0 border-b-[1px]",
        className
      )}
      {...other}
    >
      <div className={cs("flex h-full w-full min-w-max flex-wrap", justify)}>
        {children}
      </div>
    </th>
  );
};

const Table = {
  Root,
  Wrapper,
  Head,
  Body,
  Row,
  RowCell,
  HeadCell,
};

export default Table;

"use client";

import React, { useState, useRef, MouseEvent } from "react";

export interface RippleProps {
  color?: string;
  duration?: number;
}

const Ripple: React.FC<RippleProps> = ({ color = "rgba(255, 255, 255, 0.3)", duration = 600 }) => {
  const [ripples, setRipples] = useState<JSX.Element[]>([]);
  const rippleContainerRef = useRef<HTMLDivElement>(null);

  const createRipple = (event: MouseEvent<HTMLDivElement>) => {
    const container = rippleContainerRef.current;
    if (!container) return;

    const rect = container.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = event.clientX - rect.left - size / 2;
    const y = event.clientY - rect.top - size / 2;

    const newRipple = (
      <span
        key={Date.now()}
        className="absolute rounded-full animate-ripple pointer-events-none"
        style={{
          width: size,
          height: size,
          top: y,
          left: x,
          backgroundColor: color,
          opacity: 0.75,
          animationDuration: `${duration}ms`,
        }}
      />
    );

    setRipples((prevRipples) => [...prevRipples, newRipple]);

    setTimeout(() => {
      setRipples((prevRipples) => prevRipples.slice(1));
    }, duration);
  };

  return (
    <div
      ref={rippleContainerRef}
      onMouseDown={createRipple}
      className="absolute inset-0 overflow-hidden rounded-inherit"
    >
      {ripples}
    </div>
  );
};

Ripple.displayName = "Ripple";
export default Ripple;

import { Icon, Table, LinkBox } from "@pnpm-monorepo/core/src/ui/components";
import { SmallDateString } from "../../../utils/renderers";
import { Link } from "@tanstack/react-router";

function CompaniesTable({
  companies,
  sortBy,
  sortOrder,
  setSortBy,
  setSortOrder,
}) {
  return (
    <Table.Wrapper>
      <Table.Root>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>
              <button
                className="flex items-center"
                onClick={() => {
                  setSortBy("number");
                  setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                }}
              >
                Nummer{" "}
                {sortBy === "number" ? (
                  <Icon
                    icon={
                      sortOrder === "asc" ? "arrow_upward" : "arrow_downward"
                    }
                    size={18}
                    className="ml-2"
                  />
                ) : (
                  ""
                )}
              </button>
            </Table.HeadCell>
            <Table.HeadCell>
              <button
                className="flex items-center"
                onClick={() => {
                  setSortBy("name");
                  setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                }}
              >
                Namn{" "}
                {sortBy === "name" ? (
                  <Icon
                    icon={
                      sortOrder === "asc" ? "arrow_upward" : "arrow_downward"
                    }
                    size={18}
                    className="ml-2"
                  />
                ) : (
                  ""
                )}
              </button>
            </Table.HeadCell>
            <Table.HeadCell>
              <button
                className="flex items-center"
                onClick={() => {
                  setSortBy("organizationNumber");
                  setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                }}
              >
                Org.nr{" "}
                {sortBy === "organizationNumber" ? (
                  <Icon
                    icon={
                      sortOrder === "asc" ? "arrow_upward" : "arrow_downward"
                    }
                    size={18}
                    className="ml-2"
                  />
                ) : (
                  ""
                )}
              </button>
            </Table.HeadCell>
            <Table.HeadCell>
              <button
                className="flex items-center"
                onClick={() => {
                  setSortBy("place");
                  setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                }}
              >
                Område{" "}
                {sortBy === "place" ? (
                  <Icon
                    icon={
                      sortOrder === "asc" ? "arrow_upward" : "arrow_downward"
                    }
                    size={18}
                    className="ml-2"
                  />
                ) : (
                  ""
                )}
              </button>
            </Table.HeadCell>
            <Table.HeadCell>
              <button
                className="flex items-center"
                onClick={() => {
                  setSortBy("statusName");
                  setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                }}
              >
                Status{" "}
                {sortBy === "statusName" ? (
                  <Icon
                    icon={
                      sortOrder === "asc" ? "arrow_upward" : "arrow_downward"
                    }
                    size={18}
                    className="ml-2"
                  />
                ) : (
                  ""
                )}
              </button>
            </Table.HeadCell>
            <Table.HeadCell>
              <button
                className="flex items-center"
                onClick={() => {
                  setSortBy("updatedAt");
                  setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                }}
              >
                Uppdaterad{" "}
                {sortBy === "updatedAt" ? (
                  <Icon
                    icon={
                      sortOrder === "asc" ? "arrow_upward" : "arrow_downward"
                    }
                    size={18}
                    className="ml-2"
                  />
                ) : (
                  ""
                )}
              </button>
            </Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {companies?.map((company) => {
            return (
              <Table.Row key={company.id}>
                <Table.RowCell>
                  <Link
                    to={`/companies/$companyId`}
                    params={(prev) => ({
                      ...prev,
                      companyId: company.id,
                    })}
                    search={true}
                  >
                    <LinkBox>{company.number}</LinkBox>
                  </Link>
                </Table.RowCell>
                <Table.RowCell>{company.name}</Table.RowCell>
                <Table.RowCell>{company.organizationNumber}</Table.RowCell>
                <Table.RowCell>{company.place}</Table.RowCell>
                <Table.RowCell>{company.statusName}</Table.RowCell>
                <Table.RowCell>
                  <SmallDateString value={company.updatedAt} />
                </Table.RowCell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Root>
    </Table.Wrapper>
  );
}

export default CompaniesTable;

import api from "../../api";

export const fetchRequests = async () => {
    const result = await api
      .get(`requests`)
      .then((r) => r.data)
      .catch((err) => {
        throw err;
      });
  
    return result;
  };

  export async function updateRequestStatus({
    id,
    statusId,
  }: {
    id: number;
    statusId: number;
  }) {
    const result = await api
      .put(`requests/${id}/status`, {
        id,
        statusId,
      })
      .then((r) => r.data)
      .catch((err) => {
        throw err;
      });
  
    return result;
  }

  export async function deleteRequest({
    id,
  }: {
    id: number | string;
  }) {
    const result = await api
      .put(`requests/${id}/delete`, {
        id,
      })
      .then((r) => r.data)
      .catch((err) => {
        throw err;
      });
  
    return result;
  }
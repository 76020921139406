import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import React from "react";
import { LinkBox, Table } from "@pnpm-monorepo/core/src/ui/components";
import DefaultPendingComponent from "../../../../components/PendingComponent";
import RouterProgress from "../../../../components/RouterProgress";
import { NoWrap, SmallDateTimeString } from "../../../../utils/renderers";
import { requestsQueryOptions } from "../../../../utils/data/request";
import { requestStatusQueryOptions } from "../../../../utils/data/common";
import RequestStatusDropdown from "./-components/RequestStatusDropdown";
import DeleteRequest from "./-components/DeleteRequest";
import { RequestItem } from "../../../../utils/types";

export const Route = createFileRoute("/adminland/support/mobilkupongen")({
  component: SupportComponent,
});

function SupportComponent() {
  const [expandedRows, setExpandedRows] = React.useState<string[]>([]);

  const [searchDraft, setSearchDraft] = React.useState<string>("");
  const { data, isPending } = useQuery(
    requestsQueryOptions(searchDraft, [
      "ticketNumber",
      "details.name",
      "details.welcomeCode",
      "details.email",
    ])
  );

  const { data: options } = useQuery(requestStatusQueryOptions());

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = event.target.value;
    setSearchDraft(v);
  };

  const toggleRow = (rowId: string) => {
    setExpandedRows((prev) =>
      prev.includes(rowId)
        ? prev.filter((id) => id !== rowId)
        : [...prev, rowId]
    );
  };

  return (
    <>
      <div className="flex-1 flex flex-col">
        <div className="py-4 flex flex-col gap-4 grow">
          <div className="flex flex-col w-full gap-4">
            <div className="flex items-center">
              <div className="flex-1 flex items-center gap-2 lg:flex-row flex-col">
                <div className="flex-1 text-headline-large flex items-center gap-2">
                  <Link to="/adminland">
                    <LinkBox>Adminland</LinkBox>
                  </Link>{" "}
                  / Support <RouterProgress />
                </div>{" "}
                <input
                  type="text"
                  className="p-[4px] bg-surface-container-low rounded-extra-large pl-6 pr-16 text-headline-small placeholder:text-on-surface-variant"
                  placeholder="Sök..."
                  onChange={handleSearch}
                  value={searchDraft}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPending ? (
        <DefaultPendingComponent />
      ) : (
        <>
          <div className="flex flex-col w-full my-6 text-body-medium">
            {data.length ? (
              <Table.Wrapper>
                <Table.Root>
                  <Table.Head>
                    <Table.Row>
                      <Table.HeadCell></Table.HeadCell>
                      <Table.HeadCell>Ärende</Table.HeadCell>
                      <Table.HeadCell>E-post</Table.HeadCell>
                      <Table.HeadCell>Status</Table.HeadCell>
                      <Table.HeadCell>Skapad</Table.HeadCell>
                      <Table.HeadCell>Uppdaterad</Table.HeadCell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {data?.map((obj: RequestItem) => {
                      return (
                        <React.Fragment key={obj.id}>
                          <Table.Row>
                            <Table.RowCell
                              justify="justify-center"
                              className="w-16"
                            >
                              <button onClick={() => toggleRow(obj.id)}>
                                <LinkBox>
                                  {expandedRows.includes(obj.id)
                                    ? "Dölj detaljer"
                                    : "Visa detaljer"}
                                </LinkBox>
                              </button>
                            </Table.RowCell>
                            <Table.RowCell>{obj.ticketNumber}</Table.RowCell>
                            <Table.RowCell>{obj.details?.email}</Table.RowCell>

                            <Table.RowCell
                              className="w-44 min-w-44"
                              style={{ background: obj.status.color }}
                            >
                              <RequestStatusDropdown
                                row={obj}
                                options={options}
                              />
                            </Table.RowCell>
                            <Table.RowCell className="w-24">
                              <NoWrap>
                                <SmallDateTimeString value={obj.createdAt} />
                              </NoWrap>
                            </Table.RowCell>
                            <Table.RowCell className="w-24">
                              <NoWrap>
                                <SmallDateTimeString value={obj.updatedAt} />
                              </NoWrap>
                            </Table.RowCell>
                          </Table.Row>
                          {expandedRows.includes(obj.id) && (
                            <Table.Row className="bg-surface-container-lowest">
                              <td colSpan={7}>
                                <div className="p-4 flex gap-2 flex-col relative">
                                  {obj.status.id === 30 && (
                                    <div className="absolute right-4 top-4">
                                      <DeleteRequest id={obj.id} />
                                    </div>
                                  )}
                                  <div className="mt-2">
                                    <div className="text-label-medium">
                                      Namn
                                    </div>
                                    <div className="text-label-small">
                                      {obj.details?.name}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-label-medium">
                                      Välkomstkod
                                    </div>
                                    <div className="text-label-small">
                                      {obj.details?.welcomeCode}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-label-medium">
                                      Inflyttningsdatum
                                    </div>
                                    <div className="text-label-small">
                                      {obj.details?.date}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-label-medium">
                                      Meddelande
                                    </div>
                                    <div className="text-label-small">
                                      {obj.details?.message}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </Table.Row>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Table.Body>
                </Table.Root>
              </Table.Wrapper>
            ) : (
              <div className="p-3 bg-tertiary-container text-on-tertiary-container mt-6 text-center rounded-large text-body-large">
                Hittade inte några ärenden.
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

import { Link, createFileRoute, useParams } from "@tanstack/react-router";
import { useSuspenseQuery } from "@tanstack/react-query";

import { ErrorComponent } from "../../../../../components/ErrorComponent";
import DefaultPendingComponent from "../../../../../components/PendingComponent";

import Common from "./-components/Common";
import {
  contractProductPlaceQueryOptions,
  contractQueryOptions,
} from "../../../../../utils/data/contract";
import ProductPlace from "./-components/ProductPlace";
import { MdClose } from "@pnpm-monorepo/core/src/ui/icons";
import { Button } from "@pnpm-monorepo/core/src/ui/components";

export const Route = createFileRoute(
  "/companies/$companyId/contracts/$contractId"
)({
  loader: ({ context: { queryClient }, params: { contractId } }) => {
    queryClient.ensureQueryData(contractQueryOptions(contractId));
    queryClient.ensureQueryData(contractProductPlaceQueryOptions(contractId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
  component: CompanyContractComponent,
});

function CompanyContractComponent() {
  const params = Route.useParams();
  const contractQuery = useSuspenseQuery(
    contractQueryOptions(params.contractId)
  );

  const contract = contractQuery.data;

  return (
    <div className="flex-1 flex flex-col ">
      <div className="flex mb-6 justify-start gap-2">
        <Link
          to={"/companies/$companyId/contracts"}
          params={{ companyId: params.companyId }}
          search={true}
          className="flex-1 flex items-center"
        >
          <Button variant="tonal" iconLeft={<MdClose />}>
            Stäng
          </Button>
        </Link>
      </div>
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="lg:w-[560px]">
          <Common contract={contract} />
        </div>
        <div className="flex-1">
          <ProductPlace contract={contract} />
        </div>
      </div>
    </div>
  );
}

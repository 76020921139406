import React from "react";
import { useQuery } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";

import SelectWrapper from "../../../../../../components/FormWrappers/SelectWrapper";
import TextFieldWrapper from "../../../../../../components/FormWrappers/TextFieldWrapper";

import { placesQueryOptions } from "../../../../../../utils/data/common";
import { StoreType } from "../../../../../../utils/types";
import { Route } from "../route";
import { YesNo } from "../../../../../../utils/renderers";
import SwitchWrapper from "../../../../../../components/FormWrappers/SwitchWrapper";
import TextareaWrapper from "../../../../../../components/FormWrappers/TextareaWrapper";
import { useUpdateStoreMutation } from "../-api/queryOptions";

const schema = z.object({
  name: z.string().min(1, "Namn är obligatoriskt"),
  emailStatistics: z.string(),
  description: z.string(),
  active: z.boolean(),
  place: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Område är obligatoriskt" }),
});

const Common = ({ store }: { store: StoreType }) => {
  const [edit, setEdit] = React.useState(false);
  const params = Route.useParams();

  const placesQuery = useQuery(placesQueryOptions());

  const mutation = useUpdateStoreMutation(params.storeId);

  const { control, handleSubmit, reset } = useForm<StoreType>({
    resolver: zodResolver(schema),
    defaultValues: {
      id: store.id,
      name: store.name ?? "",
      emailStatistics: store.emailStatistics ?? "",
      description: store.description ?? "",
      active: store.active,
      place: store.place,
    },
  });

  const onSubmit: SubmitHandler<StoreType> = (data) => {
    return mutation.mutate(
      {
        ...data,
        id: Number(params.storeId),
      },
      {
        onSuccess: () => {
          setEdit(false);
          reset(data);
        },
      }
    );
  };

  return (
    <>
      {!edit && (
        <Card>
          <CardHeader
            title={store.name}
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(true)}
                  iconLeft={<Icon icon="edit" size={20} />}
                >
                  Redigera
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(true)}
                  icon={<Icon icon="edit" size={20} />}
                />
              </>
            }
          />
          <CardContent>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Verksamhetsnamn
              </div>
              <div className="text-body-medium">
                {store.name ? (
                  store.name
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Standardområde
              </div>
              <div className="text-body-medium">
                {store.place ? (
                  store.place.name
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                E-post erbjudandestatistik
              </div>
              <div className="text-body-medium">
                {store.emailStatistics ? (
                  store.emailStatistics
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Verksamhetstext för erbjudande
              </div>
              <div className="text-body-medium">
                {store.description ? (
                  store.description
                ) : (
                  <span className="">&lt;Ej angivet&gt;</span>
                )}
              </div>
            </div>
            <div className="mb-4">
              <div className="text-label-medium text-on-surface-variant">
                Aktiv
              </div>
              <div className="text-body-medium">
                <YesNo value={store.active} />
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      {edit && (
        <Card variant="outlined">
          <CardHeader
            title={store.name}
            actions={
              <>
                <Button
                  className="md:flex hidden"
                  onClick={() => setEdit(false)}
                  iconLeft={<Icon icon="close" size={20} />}
                >
                  Avsluta redigering
                </Button>
                <IconButton
                  variant="tonal"
                  className="md:hidden flex"
                  onClick={() => setEdit(false)}
                  icon={<Icon icon="close" />}
                />
              </>
            }
          />
          <CardContent className=" border-t-[1px] border-outline-variant">
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Verksamhetsnamn
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="name" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Standardområde
              </div>
              <div className="pt-2">
                {!placesQuery.isPending ? (
                  <SelectWrapper
                    name="place"
                    control={control}
                    className="min-w-32"
                    options={placesQuery.data}
                    getOptionLabel={(option) => option?.name}
                  />
                ) : null}
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                E-post erbjudandestatistik
              </div>
              <div className="pt-2">
                <TextFieldWrapper name="emailStatistics" control={control} />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Verksamhetstext för erbjudande
              </div>
              <div className="pt-2">
                <TextareaWrapper
                  name="description"
                  control={control}
                  rows={15}
                />
              </div>
            </div>
            <div className="mb-6">
              <div className="text-label-medium text-on-surface-variant">
                Aktiv
              </div>
              <div className="pt-2">
                <SwitchWrapper name="active" control={control} />
              </div>
            </div>
          </CardContent>
          <div className="flex justify-end gap-2 p-3">
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara & stäng
            </Button>
          </div>
        </Card>
      )}
    </>
  );
};

export default Common;

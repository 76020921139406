import { useQuery } from "@tanstack/react-query";
import { campignQueryEstimateOptions } from "../-api/queryOptions";
import {
  Button,
  Icon,
  IconButton,
} from "@pnpm-monorepo/core/src/ui/components";
import { Link, useParams } from "@tanstack/react-router";
import { cs } from "@pnpm-monorepo/core/src/utils";

const Estimate: React.FC<{ showNavButton: boolean }> = ({
  showNavButton = true,
}: {
  showNavButton: boolean;
}) => {
  const params = useParams({ from: '/campaigns/$campaignId' });
  const campignQueryEstimate = useQuery(
    campignQueryEstimateOptions(params.campaignId)
  );

  return (
    <div className="bg-tertiary-container text-on-tertiary-container rounded-large p-3 text-body-large">
      {campignQueryEstimate.isPending ? (
        <div className="flex h-full items-center gap-4">Laddar...</div>
      ) : campignQueryEstimate.isError ? (
        <div className="flex h-full items-center gap-4">
          Error: {campignQueryEstimate.error.message}
        </div>
      ) : (
        <div className="flex h-full items-center gap-4">
          <span className="block grow">
            Kampanj kommer att gå ut till ca{" "}
            <span
              className={cs(
                campignQueryEstimate.isFetching && "animate-pulse",
                ""
              )}
            >
              {campignQueryEstimate?.data}
            </span>{" "}
            konsumenter.
          </span>
          {campignQueryEstimate?.data === 0 && showNavButton ? (
            <>
              <Link
                to={`/campaigns/$campaignId/selections`}
                params={{ campaignId: params.campaignId }}
              >
                <IconButton
                  variant="outlined"
                  className="md:hidden flex"
                  icon={<Icon icon="tune" size={20} />}
                />
                <Button
                  iconLeft={<Icon icon="tune" size={20} />}
                  className="md:flex hidden"
                >
                  Uppdatera urval
                </Button>
              </Link>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Estimate;

import { useState } from "react";
import { useNavigate, useParams } from "@tanstack/react-router";

import {
  Button,
  Dialog,
  IconButton,
  Icon,
  Menu2,
} from "@pnpm-monorepo/core/src/ui/components";

import {
  campignQueryTestMailLogsQueryOptions,
  useDeleteCampaign,
  useSendTestCampaign,
} from "../-api/queryOptions";

import { CampaignType, EmailTestLogEntry } from "../../../../utils/types";
import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { SendTestMailCommandType } from "../-api/campaign";
import { useQuery } from "@tanstack/react-query";
import { SmallDateTimeString } from "../../../../utils/renderers";

export function MoreMenu({ campaign }: { campaign: CampaignType }) {
  const [open, setOpen] = useState<boolean>(false);
  const [openSendTestMail, setOpenSendTestMail] = useState<boolean>(false);

  const handleDelete = (): void => {
    setOpen(true);
  };

  const handleSendTestMail = (): void => {
    setOpenSendTestMail(true);
  };

  return (
    <>
      <Menu2.DropdownMenu>
        <Menu2.DropdownMenuTrigger asChild>
          <div>
            <IconButton icon={<Icon icon="more_vert" />} />
          </div>
        </Menu2.DropdownMenuTrigger>
        <Menu2.DropdownMenuContent align="end" alignOffset={8} forceMount>
          <Menu2.DropdownMenuItem onClick={handleSendTestMail}>
            <Icon
              icon="email"
              size={24}
              className="pr-[12px] text-on-surface-variant"
            />
            <span>Skicka ett testmail</span>
          </Menu2.DropdownMenuItem>
          <Menu2.DropdownMenuItem onClick={handleDelete}>
            <Icon
              icon="delete"
              size={24}
              className="pr-[12px] text-on-surface-variant"
            />
            <span>Ta bort</span>
          </Menu2.DropdownMenuItem>
        </Menu2.DropdownMenuContent>
      </Menu2.DropdownMenu>
      <DeleteCampaign open={open} onClose={() => setOpen(false)} />
      <SendTestMail
        open={openSendTestMail}
        onClose={() => setOpenSendTestMail(false)}
      />
    </>
  );
}

const GetSendTestLogs = () => {
  const params = useParams({ from: '/campaigns/$campaignId' });

  const { isPending, isError, data, error } = useQuery(
    campignQueryTestMailLogsQueryOptions(params.campaignId)
  );

  if (isPending) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return data.length ? (
    <div>
      <div className="text-body-medium">Testmail:</div>
      <div className="my-2 mt-4 max-h-48 overflow-y-auto">
        {data.map((mail: EmailTestLogEntry, idx: number) => (
          <div key={idx} className="text-label-medium mb-[4px]">
            <SmallDateTimeString value={mail.when} /> - {mail.email}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

const schema = z.object({
  email: z
    .string()
    .min(1, "E-postadress är obligatoriskt")
    .email("E-postadress är ogiltig"),
});

const SendTestMail = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (v: boolean) => void;
}) => {
  const params = useParams({ from: '/campaigns/$campaignId' });

  const mutation = useSendTestCampaign(parseInt(params.campaignId));

  const handleClose = (): void => {
    onClose(false);
  };

  const { control, handleSubmit, reset } = useForm<SendTestMailCommandType>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit: SubmitHandler<SendTestMailCommandType> = (data) => {
    return mutation.mutate(
      {
        ...data,
        id: parseInt(params.campaignId),
      },
      {
        onSuccess: () => {
          reset();
        },
      }
    );
  };

  return (
    <>
      <Dialog
        icon={<Icon icon="email" />}
        open={open}
        onClose={handleClose}
        headline="Skicka ett testmail"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Skicka
            </Button>
          </>
        }
      >
        <div className="my-2">
          {/* {mutation.isSuccess && (
            <div className="flex text-body-medium mb-6 my-2 p-3 bg-tertiary-container text-on-tertiary-container">
              Testmail skickat
            </div>
          )} */}
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Ange e-postaddress att skicka testmail till
            </div>
            <div className="pt-2">
              <TextFieldWrapper
                name="email"
                control={control}
                disabled={mutation.isPending}
              />
            </div>
          </div>

          <GetSendTestLogs />
        </div>
      </Dialog>
    </>
  );
};

const DeleteCampaign = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (v: boolean) => void;
}) => {
  const params = useParams({ from: '/campaigns/$campaignId' });
  const navigate = useNavigate({  from: '/campaigns/$campaignId' });

  const deleteMutation = useDeleteCampaign(parseInt(params.campaignId));

  const handleClose = (): void => {
    onClose(false);
  };

  const handleOk = (): void => {
    deleteMutation.mutate(
      {
        id: parseInt(params.campaignId),
      },
      {
        onSuccess: () => {
          navigate({
            to: "/campaigns",
            search: (old) => ({
              ...old,
            }),
          });
          handleClose();
        },
      }
    );
  };

  return (
    <>
      <Dialog
        icon={<Icon icon="delete" />}
        open={open}
        onClose={handleClose}
        headline="Ta bort kampanj?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={deleteMutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              className="bg-error text-on-error"
              onClick={handleOk}
              disabled={deleteMutation.isPending}
            >
              Ta bort
            </Button>
          </>
        }
      ></Dialog>
    </>
  );
};

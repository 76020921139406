import {
  Button,
  Icon,
  IconButton,
  MultipleComboBox,
  NavigationDrawer,
} from "@pnpm-monorepo/core/src/ui/components";
import { PlaceType, SellerType, StatusType } from "../../../utils/types";
import { companyStatusesQueryOptions, placesQueryOptions, sellersQueryOptions } from "../../../utils/data/common";
import { useQuery } from "@tanstack/react-query";

interface FilterDrawerProps {
  open: boolean;
  matches: boolean;
  onClose: () => void;
  onClearAll: () => void;
  search: string;
  places: PlaceType[] | undefined;
  companyStatuses: StatusType[] | undefined;
  sellers: SellerType[] | undefined;
  selectedPlaces: PlaceType[];
  setSelectedPlaces: React.Dispatch<React.SetStateAction<PlaceType[]>>;
  selectedStatuses: StatusType[];
  setSelectedStatuses: React.Dispatch<React.SetStateAction<StatusType[]>>;
  selectedSellers: SellerType[];
  setSelectedSellers: React.Dispatch<React.SetStateAction<SellerType[]>>;
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({
  open,
  onClose,
  onClearAll,
  selectedPlaces,
  setSelectedPlaces,
  selectedStatuses,
  setSelectedStatuses,
  selectedSellers,
  setSelectedSellers,
  places,
  companyStatuses,
  sellers,
  matches,
  search,
}) => {
  
  return (
    <div>
      <NavigationDrawer
        open={open}
        variant={matches ? "standard" : "modal"}
        onClose={onClose}
      >
        <div className="w-full h-full overflow-auto border-l-[1px] border-outline-variant">
          <div className="p-6 py-5 block mb-16">
            <div className="flex items-center justify-end gap-1">
              <Button
                disabled={
                  selectedPlaces.length === 0 &&
                  selectedStatuses.length === 0 &&
                  selectedSellers.length === 0 &&
                  search === ""
                }
                iconLeft={<Icon icon="refresh" size={24} />}
                onClick={onClearAll}
              >
                Återställ alla
              </Button>
              <IconButton icon={<Icon icon="close" />} onClick={onClose} />
            </div>
            <div className="text-title-medium mt-2 py-1">Filter</div>
            <div className="my-8 flex flex-col gap-4">
              {places && (
                <MultipleComboBox
                  items={places}
                  selectedItems={selectedPlaces}
                  setSelectedItems={setSelectedPlaces}
                  placeholder={"Område"}
                />
              )}
              {companyStatuses && (
                <MultipleComboBox
                  items={companyStatuses}
                  selectedItems={selectedStatuses}
                  setSelectedItems={setSelectedStatuses}
                  placeholder={"Status"}
                />
              )}
              {sellers && (
                <MultipleComboBox
                  items={sellers}
                  selectedItems={selectedSellers}
                  setSelectedItems={setSelectedSellers}
                  placeholder={"Säljare"}
                />
              )}
            </div>
          </div>
        </div>
      </NavigationDrawer>
    </div>
  );
};

export default FilterDrawer;

import {
    Button,
    Icon,
    IconButton,
    MultipleComboBox,
    NavigationDrawer,
  } from "@pnpm-monorepo/core/src/ui/components";
  import { StatusType } from "../../../utils/types";
  
  interface FilterDrawerProps {
    open: boolean;
    matches: boolean;
    onClose: () => void;
    onClearAll: () => void;
    search: string;
    campignStatuses: StatusType[] | undefined;
    selectedStatus: StatusType[];
    setSelectedStatus: React.Dispatch<React.SetStateAction<StatusType[]>>;

  }
  
  const FilterDrawer: React.FC<FilterDrawerProps> = ({
    open,
    onClose,
    onClearAll,
    selectedStatus,
    setSelectedStatus,
    campignStatuses,
    matches,
    search
  }) => {
    return (
      <div>
        <NavigationDrawer
          open={open}
          variant={matches ? "standard" : "modal"}
          onClose={onClose}
        >
          <div className="w-full h-full overflow-auto border-l-[1px] border-outline-variant">
            <div className="p-6 py-5 block mb-16">
              <div className="flex items-center justify-end gap-1">
                <Button
                  disabled={
                    selectedStatus.length === 0 && search === ""
                  }
                  iconLeft={<Icon icon="refresh" size={24} />}
                  onClick={onClearAll}
                >
                  Återställ alla
                </Button>
                <IconButton icon={<Icon icon="close" />} onClick={onClose} />
              </div>
              <div className="text-title-medium mt-2 py-1">Filter</div>
              <div className="my-8 flex flex-col gap-4">
                {campignStatuses && (
                  <MultipleComboBox
                    items={campignStatuses}
                    selectedItems={selectedStatus}
                    setSelectedItems={setSelectedStatus}
                    placeholder={"Status"}
                  />
                )}

              </div>
            </div>
          </div>
        </NavigationDrawer>
      </div>
    );
  };
  
  export default FilterDrawer;
  
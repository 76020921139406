import { TopAppBar } from "@pnpm-monorepo/core/src/ui/components";
import { createFileRoute, Outlet } from "@tanstack/react-router";
import Tabs from "../../components/Tabs";

export const Route = createFileRoute("/analytics")({
  component: Index,
});

function Index() {
  return (
    <div className="relative flex-1 flex flex-col">
      <TopAppBar
        variant="small"
        headline="Analys"
        className="sticky top-0 z-50 bg-surface"
      />

      <div className="flex-1 flex flex-col">
        <div className="p-6 flex flex-col gap-4 grow">
          <div className=" sticky top-[56px] z-50 bg-surface">
            <Tabs
              tabs={[
                {
                  id: "1",
                  header: "Erbjudande",
                  to: "/analytics/",
                  search: true,
                  activeOptions: { exact: true },
                },
                {
                  id: "2",
                  header: "Butik",
                  to: "/analytics/store",
                  search: true,
                  activeOptions: { exact: false },
                },
              ]}
              className="w-full lg:max-w-screen-2xl mx-auto mb-6"
            />
          </div>

          <div className="flex justify-center gap-4 mx-auto w-full h-full lg:max-w-screen-2xl">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

import { ErrorComponent, createFileRoute } from "@tanstack/react-router";

import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale
dayjs.locale("sv"); // use locale

import DefaultPendingComponent from "../../../../components/PendingComponent";
import { useSuspenseQuery } from "@tanstack/react-query";
import {
  receiverAffiliationsQuery,
  useUpdateReceiverAffiliationMutation,
  useUpdateReceiverAffiliationsMutation,
} from "../-api/queryOptions";
import {
  Button,
  Dialog,
  Icon,
  IconButton,
  Table,
} from "@pnpm-monorepo/core/src/ui/components";
import { NoWrap, SmallDateString } from "../../../../utils/renderers";
import { RecevierAffiliationType } from "../../../../utils/types";
import TextFieldWrapper from "../../../../components/FormWrappers/TextFieldWrapper";
import { UpdateReceiverWelcomeOfferCompanyCommandType } from "../-api/receiver";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useState } from "react";

const schema = z.object({
  welcomeOfferActiveDate: z
    .string()
    .min(1, { message: "Knytning i RUS är obligatoriskt" }),
});

const Edit = ({
  receiverId,
  affiliation,
}: {
  receiverId: number;
  affiliation: RecevierAffiliationType;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const { control, handleSubmit, reset } =
    useForm<UpdateReceiverWelcomeOfferCompanyCommandType>({
      resolver: zodResolver(schema),
      defaultValues: {
        id: affiliation.id,
        receiverId: receiverId,
        welcomeOfferActiveDate: affiliation.welcomeOfferActiveDate
          ? dayjs(affiliation.welcomeOfferActiveDate).format("YYYY-MM-DD")
          : undefined,
      },
    });

  const mutation = useUpdateReceiverAffiliationMutation(
    affiliation.id,
    receiverId
  );

  function handleEdit(): void {
    setOpen(true);
  }

  function handleClose(): void {
    setOpen(false);
  }

  const onSubmit: SubmitHandler<
    UpdateReceiverWelcomeOfferCompanyCommandType
  > = (data) => {
    console.log(data);
    return mutation.mutate(
      {
        ...data,
        receiverId: receiverId,
        id: affiliation.id,
      },
      {
        onSuccess: () => {
          reset(data);
        },
      }
    );
  };

  return (
    <>
      <IconButton icon={<Icon icon="edit" />} onClick={handleEdit} />
      <Dialog
        open={open}
        onClose={handleClose}
        icon={<Icon icon="edit" />}
        headline="Redigera företagsknytning"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Spara
            </Button>
          </>
        }
      >
        <div className="my-2">
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Företag
            </div>
            <div className="text-body-medium">
              {affiliation.company ? (
                <>{affiliation.company.name}</>
              ) : (
                <span className="">&lt;Ej angivet&gt;</span>
              )}
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Knytning i RUS
            </div>

            <div className="pt-2">
              <TextFieldWrapper
                name="welcomeOfferActiveDate"
                control={control}
                variant="date"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="text-label-medium text-on-surface-variant">
              Skapad i MK
            </div>
            <div className="text-body-medium">
              {affiliation.createdAt ? (
                <span>
                  <SmallDateString value={affiliation.createdAt} />
                </span>
              ) : (
                <span className="">&lt;Ej angivet&gt;</span>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

function Result({ companies }: { companies: unknown[] }) {
  const params = Route.useParams();

  return companies.length ? (
    <>
      <Table.Wrapper>
        <Table.Root>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>Namn</Table.HeadCell>
              <Table.HeadCell>Knytning i RUS</Table.HeadCell>
              <Table.HeadCell className="border-r-0">
                Skapad i MK
              </Table.HeadCell>
              <Table.HeadCell className="sticky right-0 bg-surface-container-highest shadow-sticky-left"></Table.HeadCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {companies.map((result) => (
              <Table.Row key={result.id}>
                <Table.RowCell>
                  <NoWrap>{result.company.name}</NoWrap>
                </Table.RowCell>
                <Table.RowCell>
                  <SmallDateString value={result.welcomeOfferActiveDate} />
                </Table.RowCell>
                <Table.RowCell className="border-r-0">
                  <SmallDateString value={result.createdAt} />
                </Table.RowCell>
                <Table.RowCell
                  justify="justify-center"
                  className=" sticky right-0 bg-surface overflow-hidden shadow-sticky-left w-16"
                >
                  <Edit receiverId={params.receiverId} affiliation={result} />
                </Table.RowCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </Table.Wrapper>
    </>
  ) : (
    <div className="p-3 bg-tertiary-container text-on-tertiary-container mt-6 text-center rounded-large text-body-large">
      Det finns ingen företag kopplad till inflyttad.
    </div>
  );
}

export const Route = createFileRoute("/receivers/$receiverId/affiliations")({
  component: AffiliationsComponent,
  loader: ({ context: { queryClient }, params: { receiverId } }) => {
    queryClient.ensureQueryData(receiverAffiliationsQuery(receiverId));
  },
  pendingComponent: DefaultPendingComponent,
  errorComponent: ErrorComponent,
});

function AffiliationsComponent() {
  const params = Route.useParams();

  const update = useUpdateReceiverAffiliationsMutation(params.receiverId);

  const handleUpdate = () => {
    update.mutate({ id: params.receiverId });
  };

  const affiliationQuery = useSuspenseQuery(
    receiverAffiliationsQuery(params.receiverId)
  );

  const affiliation = affiliationQuery.data;

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col">
        <div className="flex w-full justify-end pb-4">
          <Button
            className="md:flex hidden"
            onClick={handleUpdate}
            iconLeft={<Icon icon="update" size={20} />}
          >
            Uppdatera
          </Button>
        </div>

        <Result companies={affiliation} />
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";

export default function DefaultPendingComponent() {
  return (
    <div
      id="pending-page"
      className="flex flex-col h-full w-fill justify-center items-center"
    >
      <div className="rounded-small p-4">
        <p className="text-label-large">Laddar</p>
      </div>
    </div>
  );
}


export const DelayedPendingComponent = ({ delay = 300 }: { delay?: number }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  if (!show) return null;
  return <DefaultPendingComponent />;
};
import {
  Button,
  Icon,
  IconButton,
  Card,
  List,
  CardHeader,
  CardContent,
  Dialog,
  Search3,
} from "@pnpm-monorepo/core/src/ui/components";
import { SelectionType, SelectionOfferType } from "../-api/selections";
import { useState } from "react";
import api from "../../../../../utils/api";
import {
  useUpdateRemoveCampaignOfferMutation,
  useUpdateAddCampaignOfferMutation,
} from "../-api/queryOptions";
import { useParams } from "@tanstack/react-router";

interface OfferSelection {
  id: number;
  number: string;
  companyStatus: number;
  companyId: number;
  companyName: string;
  isCampaign: boolean;
  productActive: boolean;
  productCategoryActive: boolean;
  productName: string;
  offerWorthText: string;
  offerPrepositionText: string;
  offerServiceText: string;
  originalPrice: null;
  consumerValidDelayDays: number;
  maxConsumerDays: number;
  priority: number;
  status: number;
  statusName: string;
  imageUrl: string;
  updatedAt: string;
  validFrom: string;
  validTo: string;
  validToText: string;
}

const getItems = async (request, callback) => {
  try {
    const { data } = await api.get(
      `/offers/simplesearch?search=${request.q}`
    );
    return callback(data);
  } catch (e) {
    throw new Error(`API error: ${e?.message}`);
  }
};

const AddOffer = () => {
  const params = useParams({ from: '/campaigns/$campaignId' });
  const [open, setOpen] = useState<boolean>(false);

  const mutation = useUpdateAddCampaignOfferMutation(params.campaignId);

  const renderCustomOption = (option: OfferSelection) => {
    return (
      <div className="flex flex-col grow">
        <span className="text-label-large">{option.companyName}</span>
        <span className="text-label-small">
          {option.number} - {option.offerWorthText}{" "}
          {option.offerPrepositionText} {option.offerServiceText}
        </span>
      </div>
    );
  };

  const handleAdd = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  function handleOk(selected: OfferSelection): void {
    if (selected) {
      mutation.mutate(
        {
          campaignId: params.campaignId,
          offerId: selected.id,
        },
        {
          onSuccess: () => {
            setOpen(false);
          },
        }
      );
    }
  }

  return (
    <>
      <Button
        className="md:flex hidden"
        onClick={handleAdd}
        iconLeft={<Icon icon="add" size={20} />}
      >
        Lägg till
      </Button>
      <IconButton
        variant="tonal"
        className="md:hidden flex"
        onClick={handleAdd}
        icon={<Icon icon="add" size={20} />}
      />
      <Dialog
        icon={<Icon icon="add" />}
        open={open}
        onClose={handleClose}
        headline="Lägg till erbjudande"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Avbryt
            </Button>
          </>
        }
      >
        <div className="my-2">
          <Search3<OfferSelection>
            noResultsMessage="Vi hittade inga erbjudanden"
            placeholder="Sök efter erbjudanden"
            onSelect={handleOk}
            renderOption={renderCustomOption}
            getItems={getItems}
            getOptionLabel={(option) => option.companyName}
            loading={false}
          />
        </div>
      </Dialog>
    </>
  );
};

const OfferList: React.FC<SelectionType> = ({ offers }) => {
  const params = useParams({ from: '/campaigns/$campaignId' });

  return (
    <Card className="min-h-10">
      <CardHeader
        title="Välj vilka erbjudanden som ska ingå i kampanj"
        actions={
          <>
            <AddOffer />
          </>
        }
      />
      <CardContent>
        {offers.length ? (
          offers.map((offer: SelectionOfferType) => (
            <List
              key={offer.id}
              headline={
                <>
                  {offer.companyName} - {offer.offerWorthText} -{" "}
                  {offer.offerServiceText}
                </>
              }
              trailingItem={
                <DeleteOffer
                  offerId={offer.id}
                  campaignId={params.campaignId}
                />
              }
            />
          ))
        ) : (
          <div className="p-3 bg-tertiary-container text-center rounded-large text-body-medium f">
            Det finns inga erbjudanden.
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const DeleteOffer = ({
  campaignId,
  offerId,
}: {
  campaignId: number | string;
  offerId: number;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const deleteOffer = useUpdateRemoveCampaignOfferMutation(campaignId);

  const handleDelete = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const handleOk = (): void => {
    deleteOffer.mutate(
      {
        campaignId: campaignId,
        offerId: offerId,
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      }
    );
  };

  return (
    <div>
      <IconButton icon={<Icon icon="delete" />} onClick={handleDelete} />

      <Dialog
        icon={<Icon icon={"delete"} />}
        open={open}
        onClose={handleClose}
        headline="Vill du ta bort erbjudande från kampanj?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={deleteOffer.isPending}
            >
              Avbryt
            </Button>
            <Button
               variant="filled"
              className="bg-error text-on-error"
              onClick={handleOk}
              disabled={deleteOffer.isPending}
            >
              Ta bort
            </Button>
          </>
        }
      ></Dialog>
    </div>
  );
};

export default OfferList;

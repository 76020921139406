import { Button, Menu2, Icon } from "@pnpm-monorepo/core/src/ui/components";
import { OfferType } from "../../../../utils/types";
import { cs } from "@pnpm-monorepo/core/src/utils";
import { useUpdateOfferStatusMutation } from "../-api/queryOptions";
import CopyDialog from "./CopyDialog";
import DeleteDialog from "./DeleteDialog";

export default function StatusChange2({ offer }: { offer: OfferType }) {
  const { status } = offer;

  const updateOfferStatus = useUpdateOfferStatusMutation(offer.id);

  function handleStatusChange(statusId: number): void {
    const now = new Date(Date.now());

    if (statusId === 60 && offer.validFrom != null) {
      if (new Date(offer.validFrom) > now) {
        statusId = 50;
      }
    }

    updateOfferStatus.mutate(
      {
        id: offer.id,
        statusId: statusId,
      },
      {
        onSuccess: () => {
          if (statusId === 40) {
            if (offer.validFrom == null) {
              statusId = 50;
            } else {
              const validFrom = new Date(offer.validFrom);

              if (validFrom < now) {
                statusId = 60;
              } else {
                statusId = 50;
              }
            }

            updateOfferStatus.mutate({
              id: offer.id,
              statusId: statusId,
            });
          }
        },
      }
    );
  }

  return (
    <>
      <Menu2.DropdownMenu>
        <Menu2.DropdownMenuTrigger
          className={cs(
            "outline-none"
          )}
          asChild
        >
          <Button variant="outlined" iconLeft={<Icon icon="edit" size={20} />}>{status && status.name}</Button>
        </Menu2.DropdownMenuTrigger>
        <Menu2.DropdownMenuContent
          align="center"
          forceMount
          className="min-w-40 m-2"
        >
          {status && (status?.id === 10 || status?.id === 30) ? (
            <Menu2.DropdownMenuItem onClick={() => handleStatusChange(20)}>
              Klart för godkännande
            </Menu2.DropdownMenuItem>
          ) : null}
          {status && status?.id === 20 ? (
            <Menu2.DropdownMenuItem onClick={() => handleStatusChange(30)}>
              Avvisa
            </Menu2.DropdownMenuItem>
          ) : null}
          {status &&
          (status?.id === 20 || (status?.id >= 50 && status?.id <= 70)) ? (
            <Menu2.DropdownMenuItem onClick={() => handleStatusChange(10)}>
              Under uppbyggnad
            </Menu2.DropdownMenuItem>
          ) : null}

          {status && status?.id >= 10 && status?.id <= 30 ? (
            <Menu2.DropdownMenuItem onClick={() => handleStatusChange(40)}>
              Godkänn
            </Menu2.DropdownMenuItem>
          ) : null}

          {status && (status?.id === 70 || status?.id === 99) ? (
            <Menu2.DropdownMenuItem onClick={() => handleStatusChange(60)}>
              Aktivera
            </Menu2.DropdownMenuItem>
          ) : null}
          {status && (status?.id === 80 || status?.id === 90) ? (
            <Menu2.DropdownMenuItem onClick={() => handleStatusChange(60)}>
              Aktivera
            </Menu2.DropdownMenuItem>
          ) : null}

          {status && (status?.id === 50 || status?.id === 60) ? (
            <Menu2.DropdownMenuItem onClick={() => handleStatusChange(70)}>
              Pausa
            </Menu2.DropdownMenuItem>
          ) : null}

          {status &&
          (status?.id === 60 || status?.id === 70 || status?.id === 80) ? (
            <Menu2.DropdownMenuItem onClick={() => handleStatusChange(90)}>
              Avsluta
            </Menu2.DropdownMenuItem>
          ) : null}

          {status && status?.id >= 50 && status?.id <= 70 ? (
            <Menu2.DropdownMenuItem onClick={() => handleStatusChange(99)}>
              Ta bort
            </Menu2.DropdownMenuItem>
          ) : null}
        </Menu2.DropdownMenuContent>
      </Menu2.DropdownMenu>
      <div className="flex gap-2 items-center grow">
        <CopyDialog offer={offer} />
        {(status && status?.id < 40) || status?.id === 99 ? (
          <DeleteDialog />
        ) : null}
      </div>
    </>
  );
}

import { useState } from "react";

import dayjs from "dayjs";
import "dayjs/locale/sv"; // import locale
dayjs.locale("sv"); // use locale

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Icon,
  IconButton,
  Dialog,
} from "@pnpm-monorepo/core/src/ui/components";
import { ContractType } from "../../../../../../utils/types";
import { useQuery } from "@tanstack/react-query";
import {
  contractProductPlaceQueryOptions,
  useAddContractProductPlaceMutation,
} from "../../../../../../utils/data/contract";
import ProductPlaceList from "./ProductPlaceList";
import { SubmitHandler, useForm } from "react-hook-form";
import TextFieldWrapper from "../../../../../../components/FormWrappers/TextFieldWrapper";
import SelectWrapper from "../../../../../../components/FormWrappers/SelectWrapper";
import {
  contractStatusesQueryOptions,
  placesQueryOptions,
  productsQueryOptions,
} from "../../../../../../utils/data/common";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { isSwedishDecimal } from "../../../../../../utils/validation";
import { EditContractProductPlaceType } from "../../../../../../utils/data/contract/types";
import { Route } from "../route";

const ProductPlace = ({ contract }: { contract: ContractType }) => {

  const params = Route.useParams(); 
  const contractProductPlaceQuery = useQuery(
    contractProductPlaceQueryOptions(params.contractId)
  );

  const contractProductPlace = contractProductPlaceQuery.data;

  return (
    <Card>
      <CardHeader
        title="Produkt"
        actions={<AddNewProduct contract={contract} />}
      />
      <CardContent>
        {contractProductPlace.length ? (
          <ProductPlaceList data={contractProductPlace} />
        ) : (
          <div className="p-3 bg-tertiary-container text-center rounded-large text-body-medium">
            Det finns inga produkter.
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const schema = z.object({
  price: z
    .string()
    .refine((value) => isSwedishDecimal(value), {
      message:
        "Priset måste vara ett giltigt decimaltal i svenskt format (t.ex. 123,45)",
    })
    .nullable(),
  place: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Område är obligatoriskt" }),
  product: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Produkt är obligatoriskt" }),
  status: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, { message: "Status är obligatoriskt" }),
  validFrom: z.string().min(1, { message: "Giltigt från är obligatoriskt" }),
  validTo: z.string().nullable(),
});

const AddNewProduct = ({ contract }: { contract: ContractType }) => {
  const [open, setOpen] = useState<boolean>(false);

  const placesQuery = useQuery(placesQueryOptions());
  const productsQuery = useQuery(productsQueryOptions());
  const contractStatusesQuery = useQuery(contractStatusesQueryOptions());

  const mutation = useAddContractProductPlaceMutation(contract.id);

  const { control, handleSubmit, reset } = useForm<EditContractProductPlaceType>(
    {
      resolver: zodResolver(schema),
      defaultValues: {
        price: "0",
        validFrom: dayjs().format("YYYY-MM-DD"),
        validTo: "",
        status: null,
        place: null,
        product: null,
      },
    }
  );

  const handleClose = (): void => {
    setOpen(false);
    reset();
    mutation.reset();
  };

  const onSubmit: SubmitHandler<EditContractProductPlaceType> = async (data) => {

    return mutation.mutate(
      {
        ...data,
        contractId: contract.id,
        validTo: dayjs(data.validTo).isValid() ? data.validTo :  undefined
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  return (
    <>
      <Button
        className="md:flex hidden"
        onClick={() => setOpen(true)}
        iconLeft={<Icon icon="add" size={20} />}
      >
        Lägg till
      </Button>
      <IconButton
        variant="tonal"
        className="md:hidden flex"
        onClick={() => setOpen(true)}
        icon={<Icon icon="add" size={20} />}
      />
      <Dialog
        icon={<Icon icon="add" />}
        open={open}
        onClose={handleClose}
        headline="Lägg till produkt?"
        actions={
          <>
            <Button
              variant="text"
              onClick={handleClose}
              disabled={mutation.isPending}
            >
              Avbryt
            </Button>
            <Button
              variant="filled"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Lägg till
            </Button>
          </>
        }
      >
        <div className="my-2">
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Område
            </div>
            <div className="pt-2">
              {!placesQuery.isPending && (
                <SelectWrapper
                  name="place"
                  control={control}
                  className="min-w-32"
                  options={placesQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              )}
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Produkt
            </div>
            <div className="pt-2">
              {!productsQuery.isPending && (
                <SelectWrapper
                  name="product"
                  control={control}
                  className="min-w-32"
                  options={productsQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              )}
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Pris
            </div>
            <div className="pt-2">
              <TextFieldWrapper name="price" control={control} />
            </div>
          </div>
          <div className="mb-6 flex flex-col">
            <div className="text-label-medium text-on-surface-variant">
              Giltigt från → till
            </div>
            <div className="pt-2 flex gap-2 items-baseline w-full">
              <TextFieldWrapper
                name="validFrom"
                control={control}
                variant="date"
                className="w-full"
              />
              <span className="">
                <span> → </span>
              </span>
              <TextFieldWrapper
                name="validTo"
                control={control}
                variant="date"
                className="w-full"
              />
            </div>
          </div>
          <div className="mb-6">
            <div className="text-label-medium text-on-surface-variant">
              Status
            </div>
            <div className="pt-2">
              {!contractStatusesQuery.isPending && (
                <SelectWrapper
                  name="status"
                  control={control}
                  className="min-w-32"
                  options={contractStatusesQuery.data}
                  getOptionLabel={(option) => option?.name}
                />
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ProductPlace;

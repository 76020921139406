import { PickAsRequired } from "@tanstack/react-router";
import api from "../../../../utils/api";
import { NotFoundError } from "../../../../utils/errors";
import { GenderType, PlaceType, ReceiverType, StatusType } from "../../../../utils/types";

export type ChangeConsumerLoginCommandType = {
  id: number;
  userId: string;
  email: string;
}

export type ChangeConsumerPasswordCommandType = {
  id: number;
  userId: string;
  password: string;
}

export type ChangeConsumerIdCommandType = {
  id: number;
  userId: string;
}

export type ConsumerCommandType = {
  id: number;
  status: StatusType;
  place: PlaceType;
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  gender?: GenderType;
  dateOfBirth?: Date;
  mobileNumber?: string;
  acceptPushNotice: boolean;
  acceptTermsOfCondition: boolean;
  acceptPrivacyPolicy: boolean;
  acceptNewsLetter: boolean;
  acceptUserBehaviorStorage: boolean;
  acceptPersonalizing: boolean;
  emailVerified: boolean;
  acceptGeoFence: boolean;
  acceptGeoLocationStorage: boolean;
  acceptTrackingAcrossSites: boolean;
  acceptReviewQuestion: boolean;
};

export const fetchAzureADB2CUser = async (email: string) => {
  const result = await api
    .get(`consumers/adb2c/${email}`)
    .then((r) => r.data)
    .catch((err) => {
      if (err.response.status === 404) {
        throw new NotFoundError(`Konsument med email "${email}" hittades inte!`);
      }
      throw err;
    });

  return result;
};

export const fetchConsumerById = async (id: number | string) => {
  const result = await api
    .get(`consumers/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      if (err.response.status === 404) {
        throw new NotFoundError(`Konsument med id "${id}" hittades inte!`);
      }
      throw err;
    });

  return result;
};

export const fetchConsumerReceivers = async (id: number | string) => {
  const result = await api
    .get(`consumers/${id}/receivers`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const fetchConsumerStatuses = async () => {
  const result = await api
    .get(`/options?type=ConsumerStatus`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const fetchConsumerPlaces = async () => {
  const result = await api
    .get(`/options?type=Place`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const fetchConsumerGenders = async () => {
  const result = await api
    .get(`/options?type=Gender`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateConsumer = async ({
  id,
  ...updated
}: PickAsRequired<Partial<ConsumerCommandType>, "id">) => {
  const result = await api
    .put(`consumers/${id}`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateConsumerId = async ({
  id,
  ...updated
}: PickAsRequired<Partial<ChangeConsumerIdCommandType>, "id">) => {
  const result = await api
    .put(`consumers/${id}/id`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateConsumerLogin = async ({
  id,
  ...updated
}: PickAsRequired<Partial<ChangeConsumerLoginCommandType>, "id">) => {
  const result = await api
    .put(`consumers/${id}/login`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateConsumerPassword = async ({
  id,
  ...updated
}: PickAsRequired<Partial<ChangeConsumerPasswordCommandType>, "id">) => {
  const result = await api
    .put(`consumers/${id}/password`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateConsumerReceiverRelation = async ({
  receiverId,
  consumerId,
}: {
  receiverId: number;
  consumerId: number | string;
}) => {
  const result = await api
    .put(`receivers/${receiverId}/receiverrelation`, {
      id: receiverId,
      consumerId: consumerId,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateReceiverConsumerRelation = async ({
  id,
  ...updated
}: PickAsRequired<Partial<ReceiverType>, "id">) => {
  const result = await api
    .put(`receivers/${id}/consumerrelation`, {
      id,
      ...updated,
    })
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};


export const deleteConsumer= async ({
  id,
}: {
  id: number;
}) => {
  const result = await api
    .delete(`consumers/${id}`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const deleteConsumerReceiver = async ({
  receiverId,
}: {
  receiverId: number;
}) => {
  const result = await api
    .delete(`receivers/${receiverId}/release`)
    .then((r) => r.data)
    .catch((err) => {
      throw err;
    });

  return result;
};
import { queryOptions } from "@tanstack/react-query";
import {
  fetchChains,
  fetchCompanyStatuses,
  fetchSellers,
  fetchIndustries,
  fetchContractStatuses,
  fetchPlaces,
  fetchProducts,
  fetchStudioUsers,
  fetchCampaignBasisStatuses,
  fetchWeeks,
  fetchOfferStatuses,
  fetchRequestStatuses,
} from "./common";

export const companyStatusesQueryOptions = () =>
  queryOptions({
    queryKey: ["options", "companystatus"],
    queryFn: () => fetchCompanyStatuses(),
  });

export const contractStatusesQueryOptions = () =>
  queryOptions({
    queryKey: ["options", "contractstatus"],
    queryFn: () => fetchContractStatuses(),
  });

export const offerStatusesQueryOptions = () =>
  queryOptions({
    queryKey: ["options", "offerstatus"],
    queryFn: () => fetchOfferStatuses(),
  });

export const chainsQueryOptions = () =>
  queryOptions({
    queryKey: ["options", "chains"],
    queryFn: () => fetchChains(),
  });

export const sellersQueryOptions = () =>
  queryOptions({
    queryKey: ["options", "sellers"],
    queryFn: () => fetchSellers(),
  });

export const industriesQueryOptions = () =>
  queryOptions({
    queryKey: ["options", "industries"],
    queryFn: () => fetchIndustries(),
  });

export const placesQueryOptions = () =>
  queryOptions({
    queryKey: ["options", "places"],
    queryFn: () => fetchPlaces(),
  });

export const productsQueryOptions = () =>
  queryOptions({
    queryKey: ["options", "products"],
    queryFn: () => fetchProducts(),
  });

export const studioUsersQueryOptions = () =>
  queryOptions({
    queryKey: ["options", "studiousers"],
    queryFn: () => fetchStudioUsers(),
  });

export const campaignBasisStatusQueryOptions = () =>
  queryOptions({
    queryKey: ["options", "campaignbasisstatus"],
    queryFn: () => fetchCampaignBasisStatuses(),
  });

export const weeksQueryOptions = () =>
  queryOptions({
    queryKey: ["options", "weeks"],
    queryFn: () => fetchWeeks(),
  });

export const requestStatusQueryOptions = () =>
  queryOptions({
    queryKey: ["options", "requeststatus"],
    queryFn: () => fetchRequestStatuses(),
    staleTime: 1000 * 60 * 60 * 24, // Cache data for 24 hours
    refetchOnWindowFocus: false, // Don't refetch unless explicitly requested
  });

"use client";
import * as React from "react";
import { cs } from "../../../utils";

import { ButtonProps } from "./Button.types";
import Ripple from "../Ripple";

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      className,
      children,
      icon,
      iconLeft,
      iconRight,
      disabled = false,
      variant = "text",
      onClick,
      fullwidth = false,
      size = "standard",
      type,
      disableRippleEffect = false,
      ...other
    } = props;

    const variantClass = {
      text: "text-primary disabled:hover:bg-transparent hover:bg-surface-container",
      elevated: "text-primary bg-surface-container-low shadow-md hover:shadow-lg",
      outlined: "text-primary border border-outline hover:shadow-md",
      tonal: "text-on-secondary-container bg-secondary-container hover:shadow-md",
      filled: "text-on-primary bg-primary hover:shadow-lg",
    }[variant];

    let sizeClass =
      "relative flex h-10 w-max cursor-pointer items-center justify-center gap-2 overflow-hidden text-sm leading-5 tracking-[0.1px] font-normal px-4 py-2.5 rounded-full disabled:opacity-[.38] disabled:cursor-default";
    if (size === "small") {
      sizeClass += " h-8 px-4 py-1.5";
    }

    return (
      <button
        ref={ref}
        className={cs(
          sizeClass,
          "whitespace-nowrap transition-all duration-300 ease-in-out select-none",
          variantClass,
          className,
          fullwidth && "w-full"
        )}
        disabled={disabled}
        onClick={(e) => {
          if (!disabled) {
            onClick && onClick(e);
          }
        }}
        {...other}
      >
        {iconLeft && iconLeft}
        {icon && icon}
        {children && children}
        {iconRight && iconRight}
        {!disableRippleEffect && <Ripple />}
      </button>
    );
  }
);

Button.displayName = "Button";
export default Button;

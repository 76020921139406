import React from "react";

import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { zodSearchValidator, fallback } from "@tanstack/router-zod-adapter";
import { useMediaQuery } from "usehooks-ts";
import { z } from "zod";
import debounce from "lodash/debounce";

import { Button } from "@pnpm-monorepo/core/src/ui/components";

import { cs } from "@pnpm-monorepo/core/src/utils";
import ErrorEmoji from "../../components/ErrorEmoji";
import {
  PlaceType,
  SellerType,
  SortOrder,
  StatusType,
} from "../../utils/types";
import { companiesQueryOptions } from "./-api/queryOptions";
import { CompaniesSortBy } from "./-api/companies";
import { DelayedPendingComponent } from "../../components/PendingComponent";
import Pagination from "../../components/Pagination";
import {
  companyStatusesQueryOptions,
  placesQueryOptions,
  sellersQueryOptions,
} from "../../utils/data/common";
import FilterDrawer from "./-components/FilterDrawer";
import SearchBar from "./-components/SearchBar";
import CompaniesTable from "./-components/CompaniesTable";

const companySearchSchema = z.object({
  page: fallback(z.number(), 1).default(1),
  sortBy: fallback(
    z.enum([
      "number",
      "name",
      "organizationNumber",
      "place",
      "statusName",
      "updatedAt",
    ]),
    "updatedAt"
  ).default("updatedAt"),
  sortOrder: fallback(z.enum(["asc", "desc"]), "desc").default("desc"),
  q: fallback(z.string(), "").default(""),
  place: fallback(z.number().array(), []).default([]),
  status: fallback(z.number().array(), []).default([]),
  seller: fallback(z.number().array(), []).default([]),
});

export type CompaniesSearchParams = z.infer<typeof companySearchSchema>;

export const Route = createFileRoute("/companies")({
  validateSearch: zodSearchValidator(companySearchSchema),
  loaderDeps: ({ search }: { search: CompaniesSearchParams }) => ({
    q: search.q,
    page: search.page,
    sortBy: search.sortBy,
    sortOrder: search.sortOrder,
    place: search.place,
    status: search.status,
    seller: search.seller,
  }),
  loader: async (opts) => {
    const queryClient = opts.context.queryClient;
    await queryClient.cancelQueries("companies");
    return queryClient.ensureQueryData(companiesQueryOptions(opts.deps));
  },
  component: CompaniesComponent,
  pendingComponent: () => <DelayedPendingComponent />
});

function CompaniesComponent() {
  const matches = useMediaQuery("(min-width: 768px)");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate({ from: Route.fullPath });
  const { page, sortBy, sortOrder, q, place, status, seller } =
    Route.useSearch();
  const companiesQuery = useSuspenseQuery(
    companiesQueryOptions(Route.useLoaderDeps())
  );

  const companies = companiesQuery.data;

  const placeQuery = useQuery(placesQueryOptions());
  const sellersQuery = useQuery(sellersQueryOptions());
  const companyStatusesQuery = useQuery(companyStatusesQueryOptions());
  const places = placeQuery.data;
  const sellers = sellersQuery.data;
  const companyStatuses = companyStatusesQuery.data;

  const [searchDraft, setSearchDraft] = React.useState(q ?? "");

  const handleSearch = (v: string) => {
    setSearchDraft(v);
  };

  React.useEffect(() => {
    const debouncedNavigate = debounce(() => {
      navigate({
        search: (old) => ({
          ...old,
          q: searchDraft,
          page: 1,
        }),
        replace: true,
      });
    }, 300);

    debouncedNavigate();

    return () => debouncedNavigate.cancel();
  }, [searchDraft, navigate]);

  const [selectedPlacesDraft, setSelectedPlacesDraft] = React.useState<
    PlaceType[]
  >([]);
  const [selectedStatusDraft, setSelectedStatusDraft] = React.useState<
    StatusType[]
  >([]);
  const [selectedSellerDraft, setSelectedSellerDraft] = React.useState<
    SellerType[]
  >([]);

  React.useEffect(() => {
    if (places && place && place.length > 0) {
      const p = places.filter((x: PlaceType) => place.includes(x.id));
      setSelectedPlacesDraft(p);
    }
  }, [place, places]);

  React.useEffect(() => {
    if (companyStatuses && status && status.length > 0) {
      const s = companyStatuses.filter((x: StatusType) =>
        status?.includes(x.id)
      );
      setSelectedStatusDraft(s);
    }
  }, [status, companyStatuses]);

  React.useEffect(() => {
    if (sellers && seller && seller.length > 0) {
      const p = sellers.filter((x: SellerType) => seller?.includes(x.id));
      setSelectedSellerDraft(p);
    }
  }, [seller, sellers]);

  const setSortBy = (sortBy: CompaniesSortBy) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          sortBy,
        };
      },
      replace: true,
    });

  const setSortOrder = (sortOrder: SortOrder) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          sortOrder,
        };
      },
      replace: true,
    });

  const setPage = (page: number) =>
    navigate({
      search: (old) => {
        return {
          ...old,
          page,
        };
      },
      replace: true,
    });

  React.useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        place: selectedPlacesDraft.map((x: PlaceType) => x.id),
      }),
      replace: true,
    });
  }, [navigate, selectedPlacesDraft]);

  React.useEffect(() => {
    navigate({
      search: (old) => {
        return {
          ...old,
          status: selectedStatusDraft.map((x: StatusType) => x.id),
        };
      },
      replace: true,
    });
  }, [navigate, selectedStatusDraft]);

  React.useEffect(() => {
    navigate({
      search: (old) => {
        return {
          ...old,
          seller: selectedSellerDraft.map((x: SellerType) => x.id),
        };
      },
      replace: true,
    });
  }, [navigate, selectedSellerDraft]);

  function handleOpenFilter(): void {
    setOpen(!open);
  }

  function handleClearFilter(): void {
    setPage(1);
    setSelectedPlacesDraft([]);
    setSelectedStatusDraft([]);
    setSelectedSellerDraft([]);
  }

  function handleClearAllFilter(): void {
    setPage(1);
    setSearchDraft("");
    setSelectedPlacesDraft([]);
    setSelectedStatusDraft([]);
    setSelectedSellerDraft([]);
  }

  return (
    <div>
      <FilterDrawer
        open={open}
        onClose={handleOpenFilter}
        onClearAll={handleClearAllFilter}
        search={searchDraft}
        selectedPlaces={selectedPlacesDraft}
        setSelectedPlaces={setSelectedPlacesDraft}
        selectedStatuses={selectedStatusDraft}
        setSelectedStatuses={setSelectedStatusDraft}
        selectedSellers={selectedSellerDraft}
        setSelectedSellers={setSelectedSellerDraft}
        places={places}
        companyStatuses={companyStatuses}
        sellers={sellers}
        matches={matches}
      />

      <div
        id="companies-content"
        className={cs(
          "relative transition-all",
          open === true && matches ? "ml-80" : "ml-0"
        )}
      >
        <SearchBar
          searchDraft={searchDraft}
          onSearchChange={handleSearch}
          onToggleFilter={handleOpenFilter}
          isFilterOpen={open}
          hasFilters={
            selectedPlacesDraft.length !== 0 ||
            selectedStatusDraft.length !== 0 ||
            selectedSellerDraft.length !== 0
          }
          onClearFilters={handleClearFilter}
        />

        {companies?.count === 0 ? (
          <ErrorEmoji message="Hittade inga företag.">
            <Button
              variant="outlined"
              className="mt-4"
              onClick={handleClearFilter}
            >
              Rensa filter och försök igen
            </Button>
          </ErrorEmoji>
        ) : (
          <>
            <Pagination
              maxVisiblePages={10}
              totalItems={companies.count}
              itemsPerPage={25}
              currentPage={page}
              onPageChange={setPage}
            />
            <div className="flex flex-col w-full my-6 px-4 text-body-medium">
              <CompaniesTable
                companies={companies?.results}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={setSortBy}
                setSortOrder={setSortOrder}
              />
              <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

import { useQuery } from "@tanstack/react-query";
import { campignQueryActivityOptions } from "../-api/queryOptions";
import { ReactNode } from "react";
import { List } from "@pnpm-monorepo/core/src/ui/components";
import { useParams } from "@tanstack/react-router";

const Item = ({ label, value }: { label: string; value: ReactNode }) => {
  return (
    <div className="flex justify-center items-center flex-col-reverse bg-primary-container text-on-primary-container p-2 rounded-large grow">
      <div className="text-label-medium ">{label}</div>
      <div className="text-body-large">{value}</div>
    </div>
  );
};

const Activity: React.FC = () => {
 const params = useParams({ from: '/campaigns/$campaignId' });
  const campignQueryEstimate = useQuery(
    campignQueryActivityOptions(params.campaignId)
  );

  return (
    <>
      <div className="bg-inverse-on-surface rounded-large p-3 text-body-large">
        {campignQueryEstimate.isPending ? (
          <div>Laddar...</div>
        ) : campignQueryEstimate.isError ? (
          <div>Error: {campignQueryEstimate.error.message}</div>
        ) : (
          <>
            <div className="">
              <div className="text-body-large mb-3">E-post</div>
              {campignQueryEstimate.data.emailActivities && (
                <div className="flex md:flex-row flex-col gap-2 w-full flex-wrap">
                  <Item
                    label="Levererade"
                    value={
                      campignQueryEstimate.data.emailActivities.emailDelivered
                    }
                  />

                  <Item
                    label="Öppnade"
                    value={
                      <>
                        {campignQueryEstimate.data.emailActivities
                          .emailDelivered !== 0
                          ? Math.round(
                              (campignQueryEstimate.data.emailActivities
                                .emailOpened /
                                campignQueryEstimate.data.emailActivities
                                  .emailDelivered) *
                                100
                            )
                          : 0}
                        % (
                        {campignQueryEstimate.data.emailActivities.emailOpened})
                      </>
                    }
                  />

                  <Item
                    label="Klickade"
                    value={
                      <>
                        {campignQueryEstimate.data.emailActivities
                          .emailOpened !== 0
                          ? Math.round(
                              (campignQueryEstimate.data.emailActivities
                                .emailClicked /
                                campignQueryEstimate.data.emailActivities
                                  .emailOpened) *
                                100
                            )
                          : 0}
                        % (
                        {campignQueryEstimate.data.emailActivities.emailClicked}
                        )
                      </>
                    }
                  />
                  <Item
                    label="Studsade"
                    value={
                      campignQueryEstimate.data.emailActivities.emailBounced
                    }
                  />
                  <Item
                    label="Unsubscribes"
                    value={
                      campignQueryEstimate.data.emailActivities.emailUnsubbed
                    }
                  />
                </div>
              )}
            </div>

            <div className="">
              <div className="text-body-large mb-3 mt-4">Push</div>
              {campignQueryEstimate.data.pushActivities && (
                <div className="flex md:flex-row flex-col gap-2 w-full">
                  <Item
                    label="Skickade"
                    value={campignQueryEstimate.data.pushActivities.total}
                  />
                </div>
              )}
            </div>

            <div className="">
              <div className="text-body-large mt-4 mb-3">Appen</div>
              {campignQueryEstimate.data.appActivities && (
                <div className="flex md:flex-row flex-col gap-2 w-full">
                  <div className="flex flex-col w-full text-body-medium bg-inverse-on-surface rounded-large">
                    {campignQueryEstimate.data.appActivities.length ? (
                      campignQueryEstimate.data?.appActivities.map(
                        (row, index) => {
                          return (
                            <List
                              key={row.offerId}
                              headline={row.description}
                              supportingText={
                                <>
                                  Öppnade: {row.viewed} / Sparade: {row.saved} /
                                  Inlösta: {row.used}
                                </>
                              }
                            />
                          );
                        }
                      )
                    ) : (
                      <div className="p-3 bg-tertiary-container text-center rounded-large">
                        Det finns ingen aktivitet i appen.
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Activity;
